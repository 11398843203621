import createLucideIcon from '../createLucideIcon';

const Book = createLucideIcon('BookIcon', [
  ['path', { d: 'M4 19.5A2.5 2.5 0 0 1 6.5 17H20', key: '126fyg' }],
  [
    'path',
    {
      d: 'M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z',
      key: '1msh16',
    },
  ],
]);

export default Book;
