import createLucideIcon from '../createLucideIcon';

const AlignStartHorizontal = createLucideIcon('AlignStartHorizontalIcon', [
  [
    'rect',
    { x: '4', y: '6', width: '6', height: '16', rx: '2', key: '1l8oni' },
  ],
  [
    'rect',
    { x: '14', y: '6', width: '6', height: '9', rx: '2', key: '16r6cq' },
  ],
  ['path', { d: 'M22 2H2', key: 'fhrpnj' }],
]);

export default AlignStartHorizontal;
