import createLucideIcon from '../createLucideIcon';

const Plug2 = createLucideIcon('Plug2Icon', [
  ['path', { d: 'M9 2v6', key: '17ngun' }],
  ['path', { d: 'M15 2v6', key: 's7yy2p' }],
  ['path', { d: 'M12 17v5', key: 'bb1du9' }],
  ['path', { d: 'M5 8h14', key: 'pcz4l3' }],
  ['path', { d: 'M6 11V8h12v3a6 6 0 1 1-12 0v0Z', key: 'nd4hoy' }],
]);

export default Plug2;
