import createLucideIcon from '../createLucideIcon';

const ListStart = createLucideIcon('ListStartIcon', [
  ['path', { d: 'M16 12H3', key: '1a2rj7' }],
  ['path', { d: 'M16 18H3', key: '12xzn7' }],
  ['path', { d: 'M10 6H3', key: 'lf8lx7' }],
  ['path', { d: 'M21 18V8a2 2 0 0 0-2-2h-5', key: '1hghli' }],
  ['path', { d: 'm16 8-2-2 2-2', key: '160uvd' }],
]);

export default ListStart;
