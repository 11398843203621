import createLucideIcon from '../createLucideIcon';

const AlignHorizontalDistributeEnd = createLucideIcon(
  'AlignHorizontalDistributeEndIcon',
  [
    [
      'rect',
      { x: '4', y: '5', width: '6', height: '14', rx: '2', key: 'ric6yp' },
    ],
    [
      'rect',
      { x: '14', y: '7', width: '6', height: '10', rx: '2', key: '1mr5t1' },
    ],
    ['path', { d: 'M10 2v20', key: 'uyc634' }],
    ['path', { d: 'M20 2v20', key: '1tx262' }],
  ],
);

export default AlignHorizontalDistributeEnd;
