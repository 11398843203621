import createLucideIcon from '../createLucideIcon';

const FileArchive = createLucideIcon('FileArchiveIcon', [
  [
    'path',
    {
      d: 'M4 22V4c0-.5.2-1 .6-1.4C5 2.2 5.5 2 6 2h8.5L20 7.5V20c0 .5-.2 1-.6 1.4-.4.4-.9.6-1.4.6h-2',
      key: '1u864v',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '10', cy: '20', r: '2', key: '1xzdoj' }],
  ['path', { d: 'M10 7V6', key: 'dljcrl' }],
  ['path', { d: 'M10 12v-1', key: 'v7bkov' }],
  ['path', { d: 'M10 18v-2', key: '1cjy8d' }],
]);

export default FileArchive;
