import createLucideIcon from '../createLucideIcon';

const FileVideo = createLucideIcon('FileVideoIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm10 11 5 3-5 3v-6Z', key: '7ntvm4' }],
]);

export default FileVideo;
