import createLucideIcon from '../createLucideIcon';

const FlipVertical2 = createLucideIcon('FlipVertical2Icon', [
  ['path', { d: 'm17 3-5 5-5-5h10', key: '1ftt6x' }],
  ['path', { d: 'm17 21-5-5-5 5h10', key: '1m0wmu' }],
  ['path', { d: 'M4 12H2', key: 'rhcxmi' }],
  ['path', { d: 'M10 12H8', key: 's88cx1' }],
  ['path', { d: 'M16 12h-2', key: '10asgb' }],
  ['path', { d: 'M22 12h-2', key: '14jgyd' }],
]);

export default FlipVertical2;
