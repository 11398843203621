import createLucideIcon from '../createLucideIcon';

const Backpack = createLucideIcon('BackpackIcon', [
  [
    'path',
    {
      d: 'M4 20V10a4 4 0 0 1 4-4h8a4 4 0 0 1 4 4v10a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2Z',
      key: 'tunmdx',
    },
  ],
  ['path', { d: 'M9 6V4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2', key: 'donm21' }],
  ['path', { d: 'M8 21v-5a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v5', key: 'xk3gvk' }],
  ['path', { d: 'M8 10h8', key: 'c7uz4u' }],
  ['path', { d: 'M8 18h8', key: '1no2b1' }],
]);

export default Backpack;
