import createLucideIcon from '../createLucideIcon';

const Sidebar = createLucideIcon('SidebarIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['line', { x1: '9', y1: '3', x2: '9', y2: '21', key: 'nvcl17' }],
]);

export default Sidebar;
