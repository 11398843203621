import createLucideIcon from '../createLucideIcon';

const AlignHorizontalJustifyStart = createLucideIcon(
  'AlignHorizontalJustifyStartIcon',
  [
    [
      'rect',
      { x: '6', y: '5', width: '6', height: '14', rx: '2', key: '1mezge' },
    ],
    [
      'rect',
      { x: '16', y: '7', width: '6', height: '10', rx: '2', key: '1di99g' },
    ],
    ['path', { d: 'M2 2v20', key: '1ivd8o' }],
  ],
);

export default AlignHorizontalJustifyStart;
