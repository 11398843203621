import createLucideIcon from '../createLucideIcon';

const Smile = createLucideIcon('SmileIcon', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['path', { d: 'M8 14s1.5 2 4 2 4-2 4-2', key: '1y1vjs' }],
  ['line', { x1: '9', y1: '9', x2: '9.01', y2: '9', key: '141aaf' }],
  ['line', { x1: '15', y1: '9', x2: '15.01', y2: '9', key: '1cyg3o' }],
]);

export default Smile;
