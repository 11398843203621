import createLucideIcon from '../createLucideIcon';

const Sticker = createLucideIcon('StickerIcon', [
  [
    'path',
    {
      d: 'M15.5 3H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V8.5L15.5 3Z',
      key: '1wis1t',
    },
  ],
  ['path', { d: 'M15 3v6h6', key: 'edgan2' }],
  ['path', { d: 'M10 16s.8 1 2 1c1.3 0 2-1 2-1', key: '1vvgv3' }],
  ['path', { d: 'M8 13h0', key: 'jdup5h' }],
  ['path', { d: 'M16 13h0', key: 'l4i2ga' }],
]);

export default Sticker;
