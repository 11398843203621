import createLucideIcon from '../createLucideIcon';

const Swords = createLucideIcon('SwordsIcon', [
  ['polyline', { points: '14.5 17.5 3 6 3 3 6 3 17.5 14.5', key: '1hfsw2' }],
  ['line', { x1: '13', y1: '19', x2: '19', y2: '13', key: '7h9f57' }],
  ['line', { x1: '16', y1: '16', x2: '20', y2: '20', key: '1b4zco' }],
  ['line', { x1: '19', y1: '21', x2: '21', y2: '19', key: 'df24kr' }],
  ['polyline', { points: '14.5 6.5 18 3 21 3 21 6 17.5 9.5', key: 'hbey2j' }],
  ['line', { x1: '5', y1: '14', x2: '9', y2: '18', key: 'acydkb' }],
  ['line', { x1: '7', y1: '17', x2: '4', y2: '20', key: '1vmq9v' }],
  ['line', { x1: '3', y1: '19', x2: '5', y2: '21', key: '139kw4' }],
]);

export default Swords;
