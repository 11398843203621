import createLucideIcon from '../createLucideIcon';

const FileUp = createLucideIcon('FileUpIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M12 12v6', key: '3ahymv' }],
  ['path', { d: 'm15 15-3-3-3 3', key: '15xj92' }],
]);

export default FileUp;
