import createLucideIcon from '../createLucideIcon';

const Sunset = createLucideIcon('SunsetIcon', [
  ['path', { d: 'M12 10V2', key: '16sf7g' }],
  ['path', { d: 'm4.93 10.93 1.41 1.41', key: '2a7f42' }],
  ['path', { d: 'M2 18h2', key: 'j10viu' }],
  ['path', { d: 'M20 18h2', key: 'wocana' }],
  ['path', { d: 'm19.07 10.93-1.41 1.41', key: '15zs5n' }],
  ['path', { d: 'M22 22H2', key: '19qnx5' }],
  ['path', { d: 'm16 6-4 4-4-4', key: '6wukr' }],
  ['path', { d: 'M16 18a4 4 0 0 0-8 0', key: '1lzouq' }],
]);

export default Sunset;
