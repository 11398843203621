import createLucideIcon from '../createLucideIcon';

const FileAudio2 = createLucideIcon('FileAudio2Icon', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v2', key: 'fkyf72' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M2 17v-3a4 4 0 0 1 8 0v3', key: '1ggdre' }],
  ['circle', { cx: '9', cy: '17', r: '1', key: 'bc1fq4' }],
  ['circle', { cx: '3', cy: '17', r: '1', key: 'vo6nti' }],
]);

export default FileAudio2;
