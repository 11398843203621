import createLucideIcon from '../createLucideIcon';

const Cog = createLucideIcon('CogIcon', [
  ['path', { d: 'M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z', key: 'sobvz5' }],
  ['path', { d: 'M12 14a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z', key: '11i496' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'M12 22v-2', key: '1osdcq' }],
  ['path', { d: 'm17 20.66-1-1.73', key: 'eq3orb' }],
  ['path', { d: 'M11 10.27 7 3.34', key: '16pf9h' }],
  ['path', { d: 'm20.66 17-1.73-1', key: 'sg0v6f' }],
  ['path', { d: 'm3.34 7 1.73 1', key: '1ulond' }],
  ['path', { d: 'M14 12h8', key: '4f43i9' }],
  ['path', { d: 'M2 12h2', key: '1t8f8n' }],
  ['path', { d: 'm20.66 7-1.73 1', key: '1ow05n' }],
  ['path', { d: 'm3.34 17 1.73-1', key: 'nuk764' }],
  ['path', { d: 'm17 3.34-1 1.73', key: '2wel8s' }],
  ['path', { d: 'm11 13.73-4 6.93', key: '794ttg' }],
]);

export default Cog;
