import createLucideIcon from '../createLucideIcon';

const Dna = createLucideIcon('DnaIcon', [
  ['path', { d: 'M2 15c6.667-6 13.333 0 20-6', key: '1pyr53' }],
  ['path', { d: 'M9 22c1.798-1.998 2.518-3.995 2.807-5.993', key: 'q3hbxp' }],
  ['path', { d: 'M15 2c-1.798 1.998-2.518 3.995-2.807 5.993', key: '80uv8i' }],
  ['path', { d: 'm17 6-2.5-2.5', key: '5cdfhj' }],
  ['path', { d: 'm14 8-1-1', key: '15nbz5' }],
  ['path', { d: 'm7 18 2.5 2.5', key: '16tu1a' }],
  ['path', { d: 'm3.5 14.5.5.5', key: 'hapbhd' }],
  ['path', { d: 'm20 9 .5.5', key: '1n7z02' }],
  ['path', { d: 'm6.5 12.5 1 1', key: 'cs35ky' }],
  ['path', { d: 'm16.5 10.5 1 1', key: '696xn5' }],
  ['path', { d: 'm10 16 1.5 1.5', key: '11lckj' }],
]);

export default Dna;
