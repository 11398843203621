import createLucideIcon from '../createLucideIcon';

const ScanLine = createLucideIcon('ScanLineIcon', [
  ['path', { d: 'M3 7V5a2 2 0 0 1 2-2h2', key: 'aa7l1z' }],
  ['path', { d: 'M17 3h2a2 2 0 0 1 2 2v2', key: '4qcy5o' }],
  ['path', { d: 'M21 17v2a2 2 0 0 1-2 2h-2', key: '6vwrx8' }],
  ['path', { d: 'M7 21H5a2 2 0 0 1-2-2v-2', key: 'ioqczr' }],
  ['line', { x1: '7', y1: '12', x2: '17', y2: '12', key: 'bc9tui' }],
]);

export default ScanLine;
