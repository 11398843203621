import createLucideIcon from '../createLucideIcon';

const Soup = createLucideIcon('SoupIcon', [
  ['path', { d: 'M12 21a9 9 0 0 0 9-9H3a9 9 0 0 0 9 9Z', key: '4rw317' }],
  ['path', { d: 'M7 21h10', key: '1b0cd5' }],
  ['path', { d: 'M19.5 12 22 6', key: 'shfsr5' }],
  [
    'path',
    {
      d: 'M16.25 3c.27.1.8.53.75 1.36-.06.83-.93 1.2-1 2.02-.05.78.34 1.24.73 1.62',
      key: 'rpc6vp',
    },
  ],
  [
    'path',
    {
      d: 'M11.25 3c.27.1.8.53.74 1.36-.05.83-.93 1.2-.98 2.02-.06.78.33 1.24.72 1.62',
      key: '1lf63m',
    },
  ],
  [
    'path',
    {
      d: 'M6.25 3c.27.1.8.53.75 1.36-.06.83-.93 1.2-1 2.02-.05.78.34 1.24.74 1.62',
      key: '97tijn',
    },
  ],
]);

export default Soup;
