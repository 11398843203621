import createLucideIcon from '../createLucideIcon';

const Scale3d = createLucideIcon('Scale3dIcon', [
  ['path', { d: 'M5 7v12h12', key: 'vtaa4r' }],
  ['path', { d: 'm5 19 6-6', key: 'jh6hbb' }],
  ['rect', { x: '3', y: '3', width: '4', height: '4', rx: '1', key: '1qeirs' }],
  [
    'rect',
    { x: '17', y: '17', width: '4', height: '4', rx: '1', key: 'b22pg0' },
  ],
]);

export default Scale3d;
