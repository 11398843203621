import createLucideIcon from '../createLucideIcon';

const Tags = createLucideIcon('TagsIcon', [
  [
    'path',
    {
      d: 'M9 5H2v7l6.29 6.29c.94.94 2.48.94 3.42 0l3.58-3.58c.94-.94.94-2.48 0-3.42L9 5Z',
      key: 'gt587u',
    },
  ],
  ['path', { d: 'M6 9.01V9', key: '1flxpt' }],
  ['path', { d: 'm15 5 6.3 6.3a2.4 2.4 0 0 1 0 3.4L17 19', key: '1cbfv1' }],
]);

export default Tags;
