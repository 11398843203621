import createLucideIcon from '../createLucideIcon';

const Train = createLucideIcon('TrainIcon', [
  [
    'rect',
    { x: '4', y: '3', width: '16', height: '16', rx: '2', key: 'u93jis' },
  ],
  ['path', { d: 'M4 11h16', key: 'mpoxn0' }],
  ['path', { d: 'M12 3v8', key: '1h2ygw' }],
  ['path', { d: 'm8 19-2 3', key: '13i0xs' }],
  ['path', { d: 'm18 22-2-3', key: '1p0ohu' }],
  ['path', { d: 'M8 15h0', key: 'q9eq1f' }],
  ['path', { d: 'M16 15h0', key: 'pzrbjg' }],
]);

export default Train;
