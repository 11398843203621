import createLucideIcon from '../createLucideIcon';

const QrCode = createLucideIcon('QrCodeIcon', [
  ['rect', { x: '3', y: '3', width: '5', height: '5', rx: '1', key: 'fue5ao' }],
  [
    'rect',
    { x: '16', y: '3', width: '5', height: '5', rx: '1', key: '1narh3' },
  ],
  [
    'rect',
    { x: '3', y: '16', width: '5', height: '5', rx: '1', key: '1ovwlo' },
  ],
  ['path', { d: 'M21 16h-3a2 2 0 0 0-2 2v3', key: '177gqh' }],
  ['path', { d: 'M21 21v.01', key: 'ents32' }],
  ['path', { d: 'M12 7v3a2 2 0 0 1-2 2H7', key: '8crl2c' }],
  ['path', { d: 'M3 12h.01', key: 'nlz23k' }],
  ['path', { d: 'M12 3h.01', key: 'n36tog' }],
  ['path', { d: 'M12 16v.01', key: '133mhm' }],
  ['path', { d: 'M16 12h1', key: '1slzba' }],
  ['path', { d: 'M21 12v.01', key: '1lwtk9' }],
  ['path', { d: 'M12 21v-1', key: '1880an' }],
]);

export default QrCode;
