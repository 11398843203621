import createLucideIcon from '../createLucideIcon';

const FileCog2 = createLucideIcon('FileCog2Icon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['circle', { cx: '12', cy: '15', r: '2', key: '1vpstw' }],
  ['path', { d: 'M12 12v1', key: '1vuyxr' }],
  ['path', { d: 'M12 17v1', key: 'y8y3f9' }],
  ['path', { d: 'm14.6 13.5-.87.5', key: 'nomeg4' }],
  ['path', { d: 'm10.27 16-.87.5', key: '1o8v95' }],
  ['path', { d: 'm14.6 16.5-.87-.5', key: 'gzu2jw' }],
  ['path', { d: 'm10.27 14-.87-.5', key: '1vlkk3' }],
]);

export default FileCog2;
