import createLucideIcon from '../createLucideIcon';

const Gem = createLucideIcon('GemIcon', [
  ['polygon', { points: '6 3 18 3 22 9 12 22 2 9', key: '1kbvml' }],
  ['path', { d: 'm12 22 4-13-3-6', key: '19hoeh' }],
  ['path', { d: 'M12 22 8 9l3-6', key: '1klo0r' }],
  ['path', { d: 'M2 9h20', key: '16fsjt' }],
]);

export default Gem;
