import createLucideIcon from '../createLucideIcon';

const BellRing = createLucideIcon('BellRingIcon', [
  ['path', { d: 'M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9', key: 'oql5b8' }],
  ['path', { d: 'M13.73 21a2 2 0 0 1-3.46 0', key: '6o5tke' }],
  ['path', { d: 'M2 8c0-2.2.7-4.3 2-6', key: '1c7u9x' }],
  ['path', { d: 'M22 8a10 10 0 0 0-2-6', key: '1vnyda' }],
]);

export default BellRing;
