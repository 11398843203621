import createLucideIcon from '../createLucideIcon';

const Waves = createLucideIcon('WavesIcon', [
  [
    'path',
    {
      d: 'M2 6c.6.5 1.2 1 2.5 1C7 7 7 5 9.5 5c2.6 0 2.4 2 5 2 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1',
      key: 'knzxuh',
    },
  ],
  [
    'path',
    {
      d: 'M2 12c.6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 2.6 0 2.4 2 5 2 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1',
      key: '2jd2cc',
    },
  ],
  [
    'path',
    {
      d: 'M2 18c.6.5 1.2 1 2.5 1 2.5 0 2.5-2 5-2 2.6 0 2.4 2 5 2 2.5 0 2.5-2 5-2 1.3 0 1.9.5 2.5 1',
      key: 'rd2r6e',
    },
  ],
]);

export default Waves;
