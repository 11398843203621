import createLucideIcon from '../createLucideIcon';

const Tv2 = createLucideIcon('Tv2Icon', [
  ['path', { d: 'M7 21h10', key: '1b0cd5' }],
  [
    'rect',
    { x: '2', y: '3', width: '20', height: '14', rx: '2', key: 'x3v2xh' },
  ],
]);

export default Tv2;
