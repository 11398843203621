import createLucideIcon from '../createLucideIcon';

const BarChart3 = createLucideIcon('BarChart3Icon', [
  ['path', { d: 'M3 3v18h18', key: '1s2lah' }],
  ['path', { d: 'M18 17V9', key: '2bz60n' }],
  ['path', { d: 'M13 17V5', key: '1frdt8' }],
  ['path', { d: 'M8 17v-3', key: '17ska0' }],
]);

export default BarChart3;
