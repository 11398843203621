import createLucideIcon from '../createLucideIcon';

const ShoppingCart = createLucideIcon('ShoppingCartIcon', [
  ['circle', { cx: '8', cy: '21', r: '1', key: 'jimo8o' }],
  ['circle', { cx: '19', cy: '21', r: '1', key: '13723u' }],
  [
    'path',
    {
      d: 'M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12',
      key: '9zh506',
    },
  ],
]);

export default ShoppingCart;
