import createLucideIcon from '../createLucideIcon';

const MegaphoneOff = createLucideIcon('MegaphoneOffIcon', [
  ['path', { d: 'M9.26 9.26 3 11v3l14.14 3.14', key: '3429n' }],
  ['path', { d: 'M21 15.34V6l-7.31 2.03', key: '4o1dh8' }],
  ['path', { d: 'M11.6 16.8a3 3 0 1 1-5.8-1.6', key: '1yl0tm' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default MegaphoneOff;
