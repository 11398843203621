import createLucideIcon from '../createLucideIcon';

const FlipHorizontal2 = createLucideIcon('FlipHorizontal2Icon', [
  ['path', { d: 'm3 7 5 5-5 5V7', key: 'couhi7' }],
  ['path', { d: 'm21 7-5 5 5 5V7', key: '6ouia7' }],
  ['path', { d: 'M12 20v2', key: '1lh1kg' }],
  ['path', { d: 'M12 14v2', key: '8jcxud' }],
  ['path', { d: 'M12 8v2', key: '1woqiv' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
]);

export default FlipHorizontal2;
