import createLucideIcon from '../createLucideIcon';

const Film = createLucideIcon('FilmIcon', [
  [
    'rect',
    {
      x: '2',
      y: '2',
      width: '20',
      height: '20',
      rx: '2.18',
      ry: '2.18',
      key: '15u6kw',
    },
  ],
  ['line', { x1: '7', y1: '2', x2: '7', y2: '22', key: 'io8xnd' }],
  ['line', { x1: '17', y1: '2', x2: '17', y2: '22', key: '1ewgih' }],
  ['line', { x1: '2', y1: '12', x2: '22', y2: '12', key: 'zvmn4p' }],
  ['line', { x1: '2', y1: '7', x2: '7', y2: '7', key: '15jszs' }],
  ['line', { x1: '2', y1: '17', x2: '7', y2: '17', key: 'qjw6jy' }],
  ['line', { x1: '17', y1: '17', x2: '22', y2: '17', key: '132yhf' }],
  ['line', { x1: '17', y1: '7', x2: '22', y2: '7', key: 'bl3oy5' }],
]);

export default Film;
