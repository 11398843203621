import createLucideIcon from '../createLucideIcon';

const Laptop2 = createLucideIcon('Laptop2Icon', [
  [
    'rect',
    {
      x: '3',
      y: '4',
      width: '18',
      height: '12',
      rx: '2',
      ry: '2',
      key: '8tl1gx',
    },
  ],
  ['line', { x1: '2', y1: '20', x2: '22', y2: '20', key: '1pxzem' }],
]);

export default Laptop2;
