import createLucideIcon from '../createLucideIcon';

const Globe2 = createLucideIcon('Globe2Icon', [
  ['path', { d: 'M15 21v-4a2 2 0 0 1 2-2h4', key: '29t6hq' }],
  [
    'path',
    {
      d: 'M7 4v2a3 3 0 0 0 3 2h0a2 2 0 0 1 2 2 2 2 0 0 0 4 0 2 2 0 0 1 2-2h3',
      key: '1q8o6e',
    },
  ],
  [
    'path',
    {
      d: 'M3 11h2a2 2 0 0 1 2 2v1a2 2 0 0 0 2 2 2 2 0 0 1 2 2v4',
      key: '10po7j',
    },
  ],
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
]);

export default Globe2;
