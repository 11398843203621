import createLucideIcon from '../createLucideIcon';

const Mouse = createLucideIcon('MouseIcon', [
  [
    'rect',
    { x: '6', y: '3', width: '12', height: '18', rx: '6', key: 'wskjtk' },
  ],
  ['path', { d: 'M12 7v4', key: 'xawao1' }],
]);

export default Mouse;
