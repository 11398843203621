import createLucideIcon from '../createLucideIcon';

const BedDouble = createLucideIcon('BedDoubleIcon', [
  ['path', { d: 'M2 20v-8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v8', key: '1k78r4' }],
  ['path', { d: 'M4 10V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4', key: 'fb3tl2' }],
  ['path', { d: 'M12 4v6', key: '1dcgq2' }],
  ['path', { d: 'M2 18h20', key: 'ajqnye' }],
]);

export default BedDouble;
