import createLucideIcon from '../createLucideIcon';

const Shuffle = createLucideIcon('ShuffleIcon', [
  ['polyline', { points: '16 3 21 3 21 8', key: '11391h' }],
  ['line', { x1: '4', y1: '20', x2: '21', y2: '3', key: 'pnd031' }],
  ['polyline', { points: '21 16 21 21 16 21', key: '1j0gwc' }],
  ['line', { x1: '15', y1: '15', x2: '21', y2: '21', key: 'ygtzor' }],
  ['line', { x1: '4', y1: '4', x2: '9', y2: '9', key: 'q17lez' }],
]);

export default Shuffle;
