import createLucideIcon from '../createLucideIcon';

const FileScan = createLucideIcon('FileScanIcon', [
  [
    'path',
    {
      d: 'M20 10V7.5L14.5 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h4.5',
      key: 'uvikde',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M16 22a2 2 0 0 1-2-2', key: '1wqh5n' }],
  ['path', { d: 'M20 22a2 2 0 0 0 2-2', key: '1l9q4k' }],
  ['path', { d: 'M20 14a2 2 0 0 1 2 2', key: '1ny6zw' }],
  ['path', { d: 'M16 14a2 2 0 0 0-2 2', key: 'ceaadl' }],
]);

export default FileScan;
