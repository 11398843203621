import createLucideIcon from '../createLucideIcon';

const Heading1 = createLucideIcon('Heading1Icon', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  ['path', { d: 'm17 12 3-2v8', key: '1hhhft' }],
]);

export default Heading1;
