import createLucideIcon from '../createLucideIcon';

const ClipboardEdit = createLucideIcon('ClipboardEditIcon', [
  [
    'rect',
    {
      x: '8',
      y: '2',
      width: '8',
      height: '4',
      rx: '1',
      ry: '1',
      key: 'wz2j3u',
    },
  ],
  [
    'path',
    {
      d: 'M10.42 12.61a2.1 2.1 0 1 1 2.97 2.97L7.95 21 4 22l.99-3.95 5.43-5.44Z',
      key: '1rgxu8',
    },
  ],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-5.5', key: 'cereej' }],
  ['path', { d: 'M4 13.5V6a2 2 0 0 1 2-2h2', key: '5ua5vh' }],
]);

export default ClipboardEdit;
