import createLucideIcon from '../createLucideIcon';

const FileSpreadsheet = createLucideIcon('FileSpreadsheetIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M8 13h2', key: 'yr2amv' }],
  ['path', { d: 'M8 17h2', key: '2yhykz' }],
  ['path', { d: 'M14 13h2', key: 'un5t4a' }],
  ['path', { d: 'M14 17h2', key: '10kma7' }],
]);

export default FileSpreadsheet;
