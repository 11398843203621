import createLucideIcon from '../createLucideIcon';

const Expand = createLucideIcon('ExpandIcon', [
  ['path', { d: 'm21 21-6-6m6 6v-4.8m0 4.8h-4.8', key: '1c15vz' }],
  ['path', { d: 'M3 16.2V21m0 0h4.8M3 21l6-6', key: '1fsnz2' }],
  ['path', { d: 'M21 7.8V3m0 0h-4.8M21 3l-6 6', key: 'hawz9i' }],
  ['path', { d: 'M3 7.8V3m0 0h4.8M3 3l6 6', key: 'u9ee12' }],
]);

export default Expand;
