import createLucideIcon from '../createLucideIcon';

const CalendarOff = createLucideIcon('CalendarOffIcon', [
  [
    'path',
    {
      d: 'M4.18 4.18A2 2 0 0 0 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 1.82-1.18',
      key: '1feomx',
    },
  ],
  ['path', { d: 'M21 15.5V6a2 2 0 0 0-2-2H9.5', key: 'yhw86o' }],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M3 10h7', key: '1wap6i' }],
  ['path', { d: 'M21 10h-5.5', key: 'quycpq' }],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default CalendarOff;
