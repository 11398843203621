import createLucideIcon from '../createLucideIcon';

const AlignEndVertical = createLucideIcon('AlignEndVerticalIcon', [
  [
    'rect',
    { x: '2', y: '4', width: '16', height: '6', rx: '2', key: '1j7b8s' },
  ],
  [
    'rect',
    { x: '9', y: '14', width: '9', height: '6', rx: '2', key: 'b2t4yo' },
  ],
  ['path', { d: 'M22 22V2', key: '12ipfv' }],
]);

export default AlignEndVertical;
