import createLucideIcon from '../createLucideIcon';

const Heading6 = createLucideIcon('Heading6Icon', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  ['circle', { cx: '19', cy: '16', r: '2', key: '15mx69' }],
  ['path', { d: 'M20 10c-2 2-3 3.5-3 6', key: 'f35dl0' }],
]);

export default Heading6;
