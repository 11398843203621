import createLucideIcon from '../createLucideIcon';

const UtensilsCrossed = createLucideIcon('UtensilsCrossedIcon', [
  [
    'path',
    {
      d: 'm16 2-2.3 2.3a3 3 0 0 0 0 4.2l1.8 1.8a3 3 0 0 0 4.2 0L22 8',
      key: 'n7qcjb',
    },
  ],
  [
    'path',
    {
      d: 'M15 15 3.3 3.3a4.2 4.2 0 0 0 0 6l7.3 7.3c.7.7 2 .7 2.8 0L15 15Zm0 0 7 7',
      key: 'd0u48b',
    },
  ],
  ['path', { d: 'm2.1 21.8 6.4-6.3', key: 'yn04lh' }],
  ['path', { d: 'm19 5-7 7', key: '194lzd' }],
]);

export default UtensilsCrossed;
