import createLucideIcon from '../createLucideIcon';

const FolderKey = createLucideIcon('FolderKeyIcon', [
  [
    'path',
    {
      d: 'M10 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v2',
      key: '203odn',
    },
  ],
  ['circle', { cx: '16', cy: '20', r: '2', key: '1vifvg' }],
  ['path', { d: 'm22 14-4.5 4.5', key: '1ef6z8' }],
  ['path', { d: 'm21 15 1 1', key: '1ejcpy' }],
]);

export default FolderKey;
