import createLucideIcon from '../createLucideIcon';

const CupSoda = createLucideIcon('CupSodaIcon', [
  [
    'path',
    {
      d: 'm6 8 1.75 12.28a2 2 0 0 0 2 1.72h4.54a2 2 0 0 0 2-1.72L18 8',
      key: '8166m8',
    },
  ],
  ['path', { d: 'M5 8h14', key: 'pcz4l3' }],
  [
    'path',
    { d: 'M7 15a6.47 6.47 0 0 1 5 0 6.47 6.47 0 0 0 5 0', key: 'yjz344' },
  ],
  ['path', { d: 'm12 8 1-6h2', key: '3ybfa4' }],
]);

export default CupSoda;
