import createLucideIcon from '../createLucideIcon';

const CalendarSearch = createLucideIcon('CalendarSearchIcon', [
  [
    'path',
    {
      d: 'M21 12V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h7.5',
      key: '18ncp8',
    },
  ],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M8 2v4', key: '1cmpym' }],
  ['path', { d: 'M3 10h18', key: '8toen8' }],
  ['path', { d: 'M18 21a3 3 0 1 0 0-6 3 3 0 0 0 0 6v0Z', key: 'mgbru4' }],
  ['path', { d: 'm22 22-1.5-1.5', key: '1x83k4' }],
]);

export default CalendarSearch;
