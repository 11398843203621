import createLucideIcon from '../createLucideIcon';

const AlignHorizontalSpaceAround = createLucideIcon(
  'AlignHorizontalSpaceAroundIcon',
  [
    [
      'rect',
      { x: '9', y: '7', width: '6', height: '10', rx: '2', key: '7k3bt6' },
    ],
    ['path', { d: 'M4 22V2', key: 'tsjzd3' }],
    ['path', { d: 'M20 22V2', key: '1bnhr8' }],
  ],
);

export default AlignHorizontalSpaceAround;
