import createLucideIcon from '../createLucideIcon';

const SmilePlus = createLucideIcon('SmilePlusIcon', [
  ['path', { d: 'M22 11v1a10 10 0 1 1-9-10', key: 'ew0xw9' }],
  ['path', { d: 'M8 14s1.5 2 4 2 4-2 4-2', key: '1y1vjs' }],
  ['line', { x1: '9', y1: '9', x2: '9.01', y2: '9', key: '141aaf' }],
  ['line', { x1: '15', y1: '9', x2: '15.01', y2: '9', key: '1cyg3o' }],
  ['path', { d: 'M16 5h6', key: '1vod17' }],
  ['path', { d: 'M19 2v6', key: '4bpg5p' }],
]);

export default SmilePlus;
