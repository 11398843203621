import createLucideIcon from '../createLucideIcon';

const Fish = createLucideIcon('FishIcon', [
  [
    'path',
    {
      d: 'M6.5 12c.94-3.46 4.94-6 8.5-6 3.56 0 6.06 2.54 7 6-.94 3.47-3.44 6-7 6s-7.56-2.53-8.5-6Z',
      key: '15baut',
    },
  ],
  ['path', { d: 'M18 12v.5', key: '18hhni' }],
  ['path', { d: 'M16 17.93a9.77 9.77 0 0 1 0-11.86', key: '16dt7o' }],
  [
    'path',
    {
      d: 'M7 10.67C7 8 5.58 5.97 2.73 5.5c-1 1.5-1 5 .23 6.5-1.24 1.5-1.24 5-.23 6.5C5.58 18.03 7 16 7 13.33',
      key: 'l9di03',
    },
  ],
  [
    'path',
    {
      d: 'M10.46 7.26C10.2 5.88 9.17 4.24 8 3h5.8a2 2 0 0 1 1.98 1.67l.23 1.4',
      key: '1kjonw',
    },
  ],
  [
    'path',
    {
      d: 'm16.01 17.93-.23 1.4A2 2 0 0 1 13.8 21H9.5a5.96 5.96 0 0 0 1.49-3.98',
      key: '1zlm23',
    },
  ],
]);

export default Fish;
