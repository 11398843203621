import createLucideIcon from '../createLucideIcon';

const Trello = createLucideIcon('TrelloIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['rect', { x: '7', y: '7', width: '3', height: '9', key: '1xk0xp' }],
  ['rect', { x: '14', y: '7', width: '3', height: '5', key: '1otkhn' }],
]);

export default Trello;
