import createLucideIcon from '../createLucideIcon';

const PlugZap = createLucideIcon('PlugZapIcon', [
  ['path', { d: 'm13 2-2 2.5h3L12 7', key: '1me98u' }],
  ['path', { d: 'M12 22v-3', key: 'kmzjlo' }],
  ['path', { d: 'M10 13v-2.5', key: '1g2mrq' }],
  ['path', { d: 'M10 12.5v-2', key: 'pcvzbb' }],
  ['path', { d: 'M14 12.5v-2', key: 'qv1toj' }],
  [
    'path',
    {
      d: 'M16 15a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h4a2 2 0 0 0 2-2v-2z',
      key: '17xmdd',
    },
  ],
]);

export default PlugZap;
