import createLucideIcon from '../createLucideIcon';

const LifeBuoy = createLucideIcon('LifeBuoyIcon', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['circle', { cx: '12', cy: '12', r: '4', key: '4exip2' }],
  ['line', { x1: '4.93', y1: '4.93', x2: '9.17', y2: '9.17', key: '1akcti' }],
  [
    'line',
    { x1: '14.83', y1: '14.83', x2: '19.07', y2: '19.07', key: 'js56sr' },
  ],
  ['line', { x1: '14.83', y1: '9.17', x2: '19.07', y2: '4.93', key: 'ca9a8b' }],
  ['line', { x1: '14.83', y1: '9.17', x2: '18.36', y2: '5.64', key: 'dsbuwx' }],
  ['line', { x1: '4.93', y1: '19.07', x2: '9.17', y2: '14.83', key: '1lkv3n' }],
]);

export default LifeBuoy;
