import createLucideIcon from '../createLucideIcon';

const Microwave = createLucideIcon('MicrowaveIcon', [
  [
    'rect',
    { x: '2', y: '4', width: '20', height: '15', rx: '2', key: '1rfv8z' },
  ],
  ['rect', { x: '6', y: '8', width: '8', height: '7', rx: '1', key: 'i43qc1' }],
  ['path', { d: 'M18 8v7', key: 'o5zi4n' }],
  ['path', { d: 'M6 19v2', key: '1loha6' }],
  ['path', { d: 'M18 19v2', key: '1dawf0' }],
]);

export default Microwave;
