import createLucideIcon from '../createLucideIcon';

const MapPin = createLucideIcon('MapPinIcon', [
  [
    'path',
    { d: 'M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z', key: '2oe9fu' },
  ],
  ['circle', { cx: '12', cy: '10', r: '3', key: 'ilqhr7' }],
]);

export default MapPin;
