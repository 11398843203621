import createLucideIcon from '../createLucideIcon';

const MonitorSmartphone = createLucideIcon('MonitorSmartphoneIcon', [
  [
    'path',
    {
      d: 'M18 8V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h8',
      key: '10dyio',
    },
  ],
  ['path', { d: 'M10 19v-3.96 3.15', key: '1irgej' }],
  ['path', { d: 'M7 19h5', key: 'qswx4l' }],
  [
    'rect',
    { x: '16', y: '12', width: '6', height: '10', rx: '2', key: 'k4sdfk' },
  ],
]);

export default MonitorSmartphone;
