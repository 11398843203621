import createLucideIcon from '../createLucideIcon';

const AlignVerticalSpaceAround = createLucideIcon(
  'AlignVerticalSpaceAroundIcon',
  [
    [
      'rect',
      { x: '7', y: '9', width: '10', height: '6', rx: '2', key: '1iy9tl' },
    ],
    ['path', { d: 'M22 20H2', key: '1p1f7z' }],
    ['path', { d: 'M22 4H2', key: '1b7qnq' }],
  ],
);

export default AlignVerticalSpaceAround;
