import createLucideIcon from '../createLucideIcon';

const Scissors = createLucideIcon('ScissorsIcon', [
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['circle', { cx: '6', cy: '18', r: '3', key: 'fqmcym' }],
  ['line', { x1: '20', y1: '4', x2: '8.12', y2: '15.88', key: '3cwkde' }],
  ['line', { x1: '14.47', y1: '14.48', x2: '20', y2: '20', key: '1keerz' }],
  ['line', { x1: '8.12', y1: '8.12', x2: '12', y2: '12', key: 'spxzcb' }],
]);

export default Scissors;
