import createLucideIcon from '../createLucideIcon';

const FileType2 = createLucideIcon('FileType2Icon', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M2 13v-1h6v1', key: '1dh9dg' }],
  ['path', { d: 'M4 18h2', key: '1xrofg' }],
  ['path', { d: 'M5 12v6', key: '150t9c' }],
]);

export default FileType2;
