import createLucideIcon from '../createLucideIcon';

const VideoOff = createLucideIcon('VideoOffIcon', [
  ['path', { d: 'M10.66 6H14a2 2 0 0 1 2 2v2.34l1 1L22 8v8', key: 'ubwiq0' }],
  [
    'path',
    {
      d: 'M16 16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2l10 10Z',
      key: '1l10zd',
    },
  ],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default VideoOff;
