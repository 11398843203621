import createLucideIcon from '../createLucideIcon';

const Dumbbell = createLucideIcon('DumbbellIcon', [
  ['path', { d: 'm6.5 6.5 11 11', key: 'f7oqzb' }],
  ['path', { d: 'm21 21-1-1', key: 'cpc6if' }],
  ['path', { d: 'm3 3 1 1', key: 'd3rpuf' }],
  ['path', { d: 'm18 22 4-4', key: '1e32o6' }],
  ['path', { d: 'm2 6 4-4', key: '189tqz' }],
  ['path', { d: 'm3 10 7-7', key: '1bxui2' }],
  ['path', { d: 'm14 21 7-7', key: '16x78n' }],
]);

export default Dumbbell;
