import createLucideIcon from '../createLucideIcon';

const Layout = createLucideIcon('LayoutIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['line', { x1: '3', y1: '9', x2: '21', y2: '9', key: '1uch6j' }],
  ['line', { x1: '9', y1: '21', x2: '9', y2: '9', key: '97zt75' }],
]);

export default Layout;
