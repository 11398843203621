import createLucideIcon from '../createLucideIcon';

const Infinity = createLucideIcon('InfinityIcon', [
  [
    'path',
    {
      d: 'M18.178 8c5.096 0 5.096 8 0 8-5.095 0-7.133-8-12.739-8-4.585 0-4.585 8 0 8 5.606 0 7.644-8 12.74-8z',
      key: '13d65y',
    },
  ],
]);

export default Infinity;
