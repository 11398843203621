import createLucideIcon from '../createLucideIcon';

const Bike = createLucideIcon('BikeIcon', [
  ['circle', { cx: '5.5', cy: '17.5', r: '3.5', key: '1noe27' }],
  ['circle', { cx: '18.5', cy: '17.5', r: '3.5', key: '15x4ox' }],
  [
    'path',
    {
      d: 'M15 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-3 11.5V14l-3-3 4-3 2 3h2',
      key: '1932na',
    },
  ],
]);

export default Bike;
