import createLucideIcon from '../createLucideIcon';

const Contact = createLucideIcon('ContactIcon', [
  ['path', { d: 'M17 18a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2', key: '1mghuy' }],
  [
    'rect',
    { x: '3', y: '4', width: '18', height: '18', rx: '2', key: '12vinp' },
  ],
  ['circle', { cx: '12', cy: '10', r: '2', key: '1yojzk' }],
  ['line', { x1: '8', y1: '2', x2: '8', y2: '4', key: '1r8a5u' }],
  ['line', { x1: '16', y1: '2', x2: '16', y2: '4', key: 'tp0trh' }],
]);

export default Contact;
