import createLucideIcon from '../createLucideIcon';

const PoundSterling = createLucideIcon('PoundSterlingIcon', [
  ['path', { d: 'M18 7c0-5.333-8-5.333-8 0', key: '1prm2n' }],
  ['path', { d: 'M10 7v14', key: '18tmcs' }],
  ['path', { d: 'M6 21h12', key: '4dkmi1' }],
  ['path', { d: 'M6 13h10', key: 'ybwr4a' }],
]);

export default PoundSterling;
