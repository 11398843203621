import createLucideIcon from '../createLucideIcon';

const Syringe = createLucideIcon('SyringeIcon', [
  ['path', { d: 'm18 2 4 4', key: '22kx64' }],
  ['path', { d: 'm17 7 3-3', key: '1w1zoj' }],
  [
    'path',
    {
      d: 'M19 9 8.7 19.3c-1 1-2.5 1-3.4 0l-.6-.6c-1-1-1-2.5 0-3.4L15 5',
      key: '1exhtz',
    },
  ],
  ['path', { d: 'm9 11 4 4', key: 'rovt3i' }],
  ['path', { d: 'm5 19-3 3', key: '59f2uf' }],
  ['path', { d: 'm14 4 6 6', key: 'yqp9t2' }],
]);

export default Syringe;
