import createLucideIcon from '../createLucideIcon';

const ListX = createLucideIcon('ListXIcon', [
  ['path', { d: 'M11 12H3', key: '51ecnj' }],
  ['path', { d: 'M16 6H3', key: '1wxfjs' }],
  ['path', { d: 'M16 18H3', key: '12xzn7' }],
  ['path', { d: 'm19 10-4 4', key: '1tz659' }],
  ['path', { d: 'm15 10 4 4', key: '1n7nei' }],
]);

export default ListX;
