import createLucideIcon from '../createLucideIcon';

const Cookie = createLucideIcon('CookieIcon', [
  [
    'path',
    { d: 'M12 2a10 10 0 1 0 10 10 4 4 0 0 1-5-5 4 4 0 0 1-5-5', key: 'laymnq' },
  ],
  ['path', { d: 'M8.5 8.5v.01', key: 'ue8clq' }],
  ['path', { d: 'M16 15.5v.01', key: '14dtrp' }],
  ['path', { d: 'M12 12v.01', key: 'u5ubse' }],
  ['path', { d: 'M11 17v.01', key: '1hyl5a' }],
  ['path', { d: 'M7 14v.01', key: 'uct60s' }],
]);

export default Cookie;
