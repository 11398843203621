import createLucideIcon from '../createLucideIcon';

const SortDesc = createLucideIcon('SortDescIcon', [
  ['path', { d: 'M11 5h10', key: '1cz7ny' }],
  ['path', { d: 'M11 9h7', key: '13ra05' }],
  ['path', { d: 'M11 13h4', key: '1p7l4v' }],
  ['path', { d: 'm3 17 3 3 3-3', key: 'd2bl7z' }],
  ['path', { d: 'M6 18V4', key: '20vmay' }],
]);

export default SortDesc;
