import createLucideIcon from '../createLucideIcon';

const Cigarette = createLucideIcon('CigaretteIcon', [
  ['path', { d: 'M18 12H2v4h16', key: '2rt1hm' }],
  ['path', { d: 'M22 12v4', key: '142cbu' }],
  ['path', { d: 'M7 12v4', key: 'jqww69' }],
  ['path', { d: 'M18 8c0-2.5-2-2.5-2-5', key: '1il607' }],
  ['path', { d: 'M22 8c0-2.5-2-2.5-2-5', key: '1gah44' }],
]);

export default Cigarette;
