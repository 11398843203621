import createLucideIcon from '../createLucideIcon';

const LampDesk = createLucideIcon('LampDeskIcon', [
  ['path', { d: 'm14 5-3 3 2 7 8-8-7-2Z', key: '1b0msb' }],
  ['path', { d: 'm14 5-3 3-3-3 3-3 3 3Z', key: '1uemms' }],
  ['path', { d: 'M9.5 6.5 4 12l3 6', key: '1bx08v' }],
  ['path', { d: 'M3 22v-2c0-1.1.9-2 2-2h4a2 2 0 0 1 2 2v2H3Z', key: 'wap775' }],
]);

export default LampDesk;
