import createLucideIcon from '../createLucideIcon';

const TimerReset = createLucideIcon('TimerResetIcon', [
  ['path', { d: 'M10 2h4', key: 'n1abiw' }],
  ['path', { d: 'M12 14v-4', key: '1evpnu' }],
  ['path', { d: 'M4 13a8 8 0 0 1 8-7 8 8 0 1 1-5.3 14L4 17.6', key: '1ts96g' }],
  ['path', { d: 'M9 17H4v5', key: '8t5av' }],
]);

export default TimerReset;
