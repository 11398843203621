import createLucideIcon from '../createLucideIcon';

const PauseOctagon = createLucideIcon('PauseOctagonIcon', [
  ['path', { d: 'M10 15V9', key: '1lckn7' }],
  ['path', { d: 'M14 15V9', key: '1muqhk' }],
  [
    'path',
    {
      d: 'M7.714 2h8.572L22 7.714v8.572L16.286 22H7.714L2 16.286V7.714L7.714 2z',
      key: '1m7qra',
    },
  ],
]);

export default PauseOctagon;
