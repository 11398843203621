import createLucideIcon from '../createLucideIcon';

const Minimize2 = createLucideIcon('Minimize2Icon', [
  ['polyline', { points: '4 14 10 14 10 20', key: '11kfnr' }],
  ['polyline', { points: '20 10 14 10 14 4', key: 'rlmsce' }],
  ['line', { x1: '14', y1: '10', x2: '21', y2: '3', key: '6dvi8v' }],
  ['line', { x1: '3', y1: '21', x2: '10', y2: '14', key: 'c1a6xr' }],
]);

export default Minimize2;
