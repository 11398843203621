import createLucideIcon from '../createLucideIcon';

const CalendarClock = createLucideIcon('CalendarClockIcon', [
  [
    'path',
    {
      d: 'M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5',
      key: '1osxxc',
    },
  ],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'M8 2v4', key: '1cmpym' }],
  ['path', { d: 'M3 10h5', key: 'r794hk' }],
  ['path', { d: 'M17.5 17.5 16 16.25V14', key: 're2vv1' }],
  ['path', { d: 'M22 16a6 6 0 1 1-12 0 6 6 0 0 1 12 0Z', key: 'ame013' }],
]);

export default CalendarClock;
