import createLucideIcon from '../createLucideIcon';

const Milestone = createLucideIcon('MilestoneIcon', [
  [
    'path',
    {
      d: 'M18 6H5a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h13l4-3.5L18 6Z',
      key: '1mp5s7',
    },
  ],
  ['path', { d: 'M12 13v9', key: 'keea1l' }],
  ['path', { d: 'M12 2v4', key: '3427ic' }],
]);

export default Milestone;
