import createLucideIcon from '../createLucideIcon';

const FileTerminal = createLucideIcon('FileTerminalIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm8 16 2-2-2-2', key: '10vzyd' }],
  ['path', { d: 'M12 18h4', key: '1wd2n7' }],
]);

export default FileTerminal;
