import createLucideIcon from '../createLucideIcon';

const FileQuestion = createLucideIcon('FileQuestionIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  [
    'path',
    {
      d: 'M10 10.3c.2-.4.5-.8.9-1a2.1 2.1 0 0 1 2.6.4c.3.4.5.8.5 1.3 0 1.3-2 2-2 2',
      key: '1umxtm',
    },
  ],
  ['path', { d: 'M12 17h.01', key: 'p32p05' }],
]);

export default FileQuestion;
