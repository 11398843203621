import createLucideIcon from '../createLucideIcon';

const DivideSquare = createLucideIcon('DivideSquareIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['line', { x1: '8', y1: '12', x2: '16', y2: '12', key: '1myapg' }],
  ['line', { x1: '12', y1: '16', x2: '12', y2: '16', key: '4v5xkb' }],
  ['line', { x1: '12', y1: '8', x2: '12', y2: '8', key: '1vrb7x' }],
]);

export default DivideSquare;
