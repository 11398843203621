import createLucideIcon from '../createLucideIcon';

const Grid = createLucideIcon('GridIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['line', { x1: '3', y1: '9', x2: '21', y2: '9', key: '1uch6j' }],
  ['line', { x1: '3', y1: '15', x2: '21', y2: '15', key: '1xojw2' }],
  ['line', { x1: '9', y1: '3', x2: '9', y2: '21', key: 'nvcl17' }],
  ['line', { x1: '15', y1: '3', x2: '15', y2: '21', key: 'jcv93v' }],
]);

export default Grid;
