import createLucideIcon from '../createLucideIcon';

const Egg = createLucideIcon('EggIcon', [
  [
    'path',
    {
      d: 'M12 22c6.23-.05 7.87-5.57 7.5-10-.36-4.34-3.95-9.96-7.5-10-3.55.04-7.14 5.66-7.5 10-.37 4.43 1.27 9.95 7.5 10z',
      key: '1c39pg',
    },
  ],
]);

export default Egg;
