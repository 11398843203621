import createLucideIcon from '../createLucideIcon';

const ToyBrick = createLucideIcon('ToyBrickIcon', [
  [
    'rect',
    { x: '3', y: '8', width: '18', height: '12', rx: '1', key: '1yob91' },
  ],
  ['path', { d: 'M10 8V5c0-.6-.4-1-1-1H6a1 1 0 0 0-1 1v3', key: 's0042v' }],
  ['path', { d: 'M19 8V5c0-.6-.4-1-1-1h-3a1 1 0 0 0-1 1v3', key: '9wmeh2' }],
]);

export default ToyBrick;
