import createLucideIcon from '../createLucideIcon';

const FolderSearch = createLucideIcon('FolderSearchIcon', [
  [
    'path',
    {
      d: 'M11 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v4',
      key: '1moo03',
    },
  ],
  ['circle', { cx: '17', cy: '17', r: '3', key: '18b49y' }],
  ['path', { d: 'm21 21-1.5-1.5', key: '3sg1j' }],
]);

export default FolderSearch;
