import createLucideIcon from '../createLucideIcon';

const ShoppingBag = createLucideIcon('ShoppingBagIcon', [
  [
    'path',
    { d: 'M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z', key: '14a4hr' },
  ],
  ['line', { x1: '3', y1: '6', x2: '21', y2: '6', key: '1tp2lp' }],
  ['path', { d: 'M16 10a4 4 0 0 1-8 0', key: '1ltviw' }],
]);

export default ShoppingBag;
