import createLucideIcon from '../createLucideIcon';

const CloudSun = createLucideIcon('CloudSunIcon', [
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'm4.93 4.93 1.41 1.41', key: '149t6j' }],
  ['path', { d: 'M20 12h2', key: '1q8mjw' }],
  ['path', { d: 'm19.07 4.93-1.41 1.41', key: '1shlcs' }],
  ['path', { d: 'M15.947 12.65a4 4 0 0 0-5.925-4.128', key: 'dpwdj0' }],
  ['path', { d: 'M13 22H7a5 5 0 1 1 4.9-6H13a3 3 0 0 1 0 6Z', key: 's09mg5' }],
]);

export default CloudSun;
