import createLucideIcon from '../createLucideIcon';

const ClipboardX = createLucideIcon('ClipboardXIcon', [
  [
    'rect',
    {
      x: '8',
      y: '2',
      width: '8',
      height: '4',
      rx: '1',
      ry: '1',
      key: 'wz2j3u',
    },
  ],
  [
    'path',
    {
      d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2',
      key: '116196',
    },
  ],
  ['path', { d: 'm15 11-6 6', key: '1toa9n' }],
  ['path', { d: 'm9 11 6 6', key: 'wlibny' }],
]);

export default ClipboardX;
