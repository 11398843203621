import createLucideIcon from '../createLucideIcon';

const Loader = createLucideIcon('LoaderIcon', [
  ['line', { x1: '12', y1: '2', x2: '12', y2: '6', key: '1pduqs' }],
  ['line', { x1: '12', y1: '18', x2: '12', y2: '22', key: '1b94uv' }],
  ['line', { x1: '4.93', y1: '4.93', x2: '7.76', y2: '7.76', key: '1a736z' }],
  [
    'line',
    { x1: '16.24', y1: '16.24', x2: '19.07', y2: '19.07', key: 'gt096z' },
  ],
  ['line', { x1: '2', y1: '12', x2: '6', y2: '12', key: 'rkbu33' }],
  ['line', { x1: '18', y1: '12', x2: '22', y2: '12', key: '1vrbnu' }],
  ['line', { x1: '4.93', y1: '19.07', x2: '7.76', y2: '16.24', key: '59c6el' }],
  ['line', { x1: '16.24', y1: '7.76', x2: '19.07', y2: '4.93', key: '8m03gt' }],
]);

export default Loader;
