import createLucideIcon from '../createLucideIcon';

const PackageCheck = createLucideIcon('PackageCheckIcon', [
  ['path', { d: 'm16 16 2 2 4-4', key: 'gfu2re' }],
  [
    'path',
    {
      d: 'M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14',
      key: 'e7tb2h',
    },
  ],
  ['path', { d: 'M16.5 9.4 7.55 4.24', key: '10qotr' }],
  ['polyline', { points: '3.29 7 12 12 20.71 7', key: 'ousv84' }],
  ['line', { x1: '12', y1: '22', x2: '12', y2: '12', key: 'gdv6h4' }],
]);

export default PackageCheck;
