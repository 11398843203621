import createLucideIcon from '../createLucideIcon';

const Shrub = createLucideIcon('ShrubIcon', [
  ['path', { d: 'M12 22v-7l-2-2', key: 'eqv9mc' }],
  [
    'path',
    {
      d: 'M17 8v.8A6 6 0 0 1 13.8 20v0H10v0A6.5 6.5 0 0 1 7 8h0a5 5 0 0 1 10 0Z',
      key: '12jcau',
    },
  ],
  ['path', { d: 'm14 14-2 2', key: '847xa2' }],
]);

export default Shrub;
