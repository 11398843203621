import createLucideIcon from '../createLucideIcon';

const FileSignature = createLucideIcon('FileSignatureIcon', [
  [
    'path',
    {
      d: 'M20 19.5v.5a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h8.5L18 5.5',
      key: 'kd5d3',
    },
  ],
  ['path', { d: 'M8 18h1', key: '13wk12' }],
  [
    'path',
    {
      d: 'M18.42 9.61a2.1 2.1 0 1 1 2.97 2.97L16.95 17 13 18l.99-3.95 4.43-4.44Z',
      key: 'johvi5',
    },
  ],
]);

export default FileSignature;
