import createLucideIcon from '../createLucideIcon';

const VibrateOff = createLucideIcon('VibrateOffIcon', [
  ['path', { d: 'm2 8 2 2-2 2 2 2-2 2', key: 'sv1b1' }],
  ['path', { d: 'm22 8-2 2 2 2-2 2 2 2', key: '101i4y' }],
  ['path', { d: 'M8 8v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-2', key: '1hbad5' }],
  ['path', { d: 'M16 10.34V6c0-.55-.45-1-1-1h-4.34', key: '1x5tf0' }],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default VibrateOff;
