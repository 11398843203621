import createLucideIcon from '../createLucideIcon';

const Subscript = createLucideIcon('SubscriptIcon', [
  ['path', { d: 'm4 5 8 8', key: '1eunvl' }],
  ['path', { d: 'm12 5-8 8', key: '1ah0jp' }],
  [
    'path',
    {
      d: 'M20 19h-4c0-1.5.44-2 1.5-2.5S20 15.33 20 14c0-.47-.17-.93-.48-1.29a2.11 2.11 0 0 0-2.62-.44c-.42.24-.74.62-.9 1.07',
      key: 'e8ta8j',
    },
  ],
]);

export default Subscript;
