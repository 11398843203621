import createLucideIcon from '../createLucideIcon';

const StretchHorizontal = createLucideIcon('StretchHorizontalIcon', [
  [
    'rect',
    { x: '2', y: '4', width: '20', height: '6', rx: '2', key: '12sjy4' },
  ],
  [
    'rect',
    { x: '2', y: '14', width: '20', height: '6', rx: '2', key: 'lnm6uo' },
  ],
]);

export default StretchHorizontal;
