import createLucideIcon from '../createLucideIcon';

const HandMetal = createLucideIcon('HandMetalIcon', [
  [
    'path',
    { d: 'M18 12.5V10a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v1.4', key: '7eki13' },
  ],
  ['path', { d: 'M14 11V9a2 2 0 1 0-4 0v2', key: '94qvcw' }],
  ['path', { d: 'M10 10.5V5a2 2 0 1 0-4 0v9', key: 'm1ah89' }],
  [
    'path',
    {
      d: 'm7 15-1.76-1.76a2 2 0 0 0-2.83 2.82l3.6 3.6C7.5 21.14 9.2 22 12 22h2a8 8 0 0 0 8-8V7a2 2 0 1 0-4 0v5',
      key: 't1skq1',
    },
  ],
]);

export default HandMetal;
