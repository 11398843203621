import createLucideIcon from '../createLucideIcon';

const ListOrdered = createLucideIcon('ListOrderedIcon', [
  ['line', { x1: '10', y1: '6', x2: '21', y2: '6', key: 'g7ikjt' }],
  ['line', { x1: '10', y1: '12', x2: '21', y2: '12', key: 'xgqux5' }],
  ['line', { x1: '10', y1: '18', x2: '21', y2: '18', key: '1q4fbe' }],
  ['path', { d: 'M4 6h1v4', key: 'cnovpq' }],
  ['path', { d: 'M4 10h2', key: '16xx2s' }],
  ['path', { d: 'M6 18H4c0-1 2-2 2-3s-1-1.5-2-1', key: 'm9a95d' }],
]);

export default ListOrdered;
