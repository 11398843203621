import createLucideIcon from '../createLucideIcon';

const FileType = createLucideIcon('FileTypeIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M9 13v-1h6v1', key: '1bb014' }],
  ['path', { d: 'M11 18h2', key: '12mj7e' }],
  ['path', { d: 'M12 12v6', key: '3ahymv' }],
]);

export default FileType;
