import createLucideIcon from '../createLucideIcon';

const Share2 = createLucideIcon('Share2Icon', [
  ['circle', { cx: '18', cy: '5', r: '3', key: 'gq8acd' }],
  ['circle', { cx: '6', cy: '12', r: '3', key: 'w7nqdw' }],
  ['circle', { cx: '18', cy: '19', r: '3', key: '1xt0gg' }],
  [
    'line',
    { x1: '8.59', y1: '13.51', x2: '15.42', y2: '17.49', key: '10dsx0' },
  ],
  ['line', { x1: '15.41', y1: '6.51', x2: '8.59', y2: '10.49', key: '1qn9hm' }],
]);

export default Share2;
