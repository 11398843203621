import createLucideIcon from '../createLucideIcon';

const ZapOff = createLucideIcon('ZapOffIcon', [
  ['polyline', { points: '12.41 6.75 13 2 10.57 4.92', key: '122m05' }],
  ['polyline', { points: '18.57 12.91 21 10 15.66 10', key: '16r43o' }],
  ['polyline', { points: '8 8 3 14 12 14 11 22 16 16', key: 'tmh4bc' }],
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
]);

export default ZapOff;
