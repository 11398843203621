import createLucideIcon from '../createLucideIcon';

const ToggleLeft = createLucideIcon('ToggleLeftIcon', [
  [
    'rect',
    {
      x: '1',
      y: '5',
      width: '22',
      height: '14',
      rx: '7',
      ry: '7',
      key: 'rq2fx9',
    },
  ],
  ['circle', { cx: '8', cy: '12', r: '3', key: 'nylqfu' }],
]);

export default ToggleLeft;
