import createLucideIcon from '../createLucideIcon';

const Album = createLucideIcon('AlbumIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['polyline', { points: '11 3 11 11 14 8 17 11 17 3', key: '1wcwz3' }],
]);

export default Album;
