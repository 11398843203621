import createLucideIcon from '../createLucideIcon';

const ArrowLeftRight = createLucideIcon('ArrowLeftRightIcon', [
  ['polyline', { points: '17 11 21 7 17 3', key: 'l3l6r3' }],
  ['line', { x1: '21', y1: '7', x2: '9', y2: '7', key: '17x2jj' }],
  ['polyline', { points: '7 21 3 17 7 13', key: 'lfumnw' }],
  ['line', { x1: '15', y1: '17', x2: '3', y2: '17', key: 'gusd5o' }],
]);

export default ArrowLeftRight;
