import createLucideIcon from '../createLucideIcon';

const ImageOff = createLucideIcon('ImageOffIcon', [
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
  ['path', { d: 'M10.41 10.41a2 2 0 1 1-2.83-2.83', key: '1bzlo9' }],
  ['line', { x1: '13.5', y1: '13.5', x2: '6', y2: '21', key: '1oc4ns' }],
  ['line', { x1: '18', y1: '12', x2: '21', y2: '15', key: '1j50dh' }],
  [
    'path',
    {
      d: 'M3.59 3.59A1.99 1.99 0 0 0 3 5v14a2 2 0 0 0 2 2h14c.55 0 1.052-.22 1.41-.59',
      key: 'mmje98',
    },
  ],
  ['path', { d: 'M21 15V5a2 2 0 0 0-2-2H9', key: '43el77' }],
]);

export default ImageOff;
