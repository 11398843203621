import createLucideIcon from '../createLucideIcon';

const Signal = createLucideIcon('SignalIcon', [
  ['path', { d: 'M2 20h.01', key: '4haj6o' }],
  ['path', { d: 'M7 20v-4', key: 'j294jx' }],
  ['path', { d: 'M12 20v-8', key: 'i3yub9' }],
  ['path', { d: 'M17 20V8', key: '1tkaf5' }],
  ['path', { d: 'M22 4v16', key: 'sih9yq' }],
]);

export default Signal;
