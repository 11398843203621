import createLucideIcon from '../createLucideIcon';

const FolderTree = createLucideIcon('FolderTreeIcon', [
  [
    'path',
    {
      d: 'M13 10h7a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-2.5a1 1 0 0 1-.8-.4l-.9-1.2A1 1 0 0 0 15 3h-2a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z',
      key: '10jzg2',
    },
  ],
  [
    'path',
    {
      d: 'M13 21h7a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-2.88a1 1 0 0 1-.9-.55l-.44-.9a1 1 0 0 0-.9-.55H13a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1Z',
      key: '1b9nqm',
    },
  ],
  ['path', { d: 'M3 3v2c0 1.1.9 2 2 2h3', key: '1wqwis' }],
  ['path', { d: 'M3 3v13c0 1.1.9 2 2 2h3', key: '1bqeom' }],
]);

export default FolderTree;
