import createLucideIcon from '../createLucideIcon';

const GitBranch = createLucideIcon('GitBranchIcon', [
  ['line', { x1: '6', y1: '3', x2: '6', y2: '15', key: '1o40i7' }],
  ['circle', { cx: '18', cy: '6', r: '3', key: '1h7g24' }],
  ['circle', { cx: '6', cy: '18', r: '3', key: 'fqmcym' }],
  ['path', { d: 'M18 9a9 9 0 0 1-9 9', key: 'n2h4wq' }],
]);

export default GitBranch;
