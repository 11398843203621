import createLucideIcon from '../createLucideIcon';

const CloudRainWind = createLucideIcon('CloudRainWindIcon', [
  [
    'path',
    {
      d: 'M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242',
      key: '1pljnt',
    },
  ],
  ['path', { d: 'm9.2 22 3-7', key: 'sb5f6j' }],
  ['path', { d: 'm9 13-3 7', key: '500co5' }],
  ['path', { d: 'm17 13-3 7', key: '8t2fiy' }],
]);

export default CloudRainWind;
