import createLucideIcon from '../createLucideIcon';

const Frame = createLucideIcon('FrameIcon', [
  ['line', { x1: '22', y1: '6', x2: '2', y2: '6', key: '181agm' }],
  ['line', { x1: '22', y1: '18', x2: '2', y2: '18', key: '12x4ne' }],
  ['line', { x1: '6', y1: '2', x2: '6', y2: '22', key: 'gjs6u1' }],
  ['line', { x1: '18', y1: '2', x2: '18', y2: '22', key: '1hbgm0' }],
]);

export default Frame;
