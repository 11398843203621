import createLucideIcon from '../createLucideIcon';

const Hash = createLucideIcon('HashIcon', [
  ['line', { x1: '4', y1: '9', x2: '20', y2: '9', key: 'vg9vz1' }],
  ['line', { x1: '4', y1: '15', x2: '20', y2: '15', key: '12vs86' }],
  ['line', { x1: '10', y1: '3', x2: '8', y2: '21', key: '18wc2u' }],
  ['line', { x1: '16', y1: '3', x2: '14', y2: '21', key: 'ohqwl5' }],
]);

export default Hash;
