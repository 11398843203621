import createLucideIcon from '../createLucideIcon';

const SortAsc = createLucideIcon('SortAscIcon', [
  ['path', { d: 'M11 11h4', key: '1iu023' }],
  ['path', { d: 'M11 15h7', key: '23lz64' }],
  ['path', { d: 'M11 19h10', key: '11t30w' }],
  ['path', { d: 'M9 7 6 4 3 7', key: '1s6vko' }],
  ['path', { d: 'M6 6v14', key: '1s15cj' }],
]);

export default SortAsc;
