import createLucideIcon from '../createLucideIcon';

const ServerOff = createLucideIcon('ServerOffIcon', [
  ['path', { d: 'M7 2h13a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-5', key: 'bt2siv' }],
  [
    'path',
    { d: 'M10 10 2.5 2.5C2 2 2 2.5 2 5v3a2 2 0 0 0 2 2h6z', key: '1hjrv1' },
  ],
  ['path', { d: 'M22 17v-1a2 2 0 0 0-2-2h-1', key: '1iynyr' }],
  [
    'path',
    {
      d: 'M4 14a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16.5l1-.5.5.5-8-8H4z',
      key: '161ggg',
    },
  ],
  ['path', { d: 'M6 18h.01', key: 'uhywen' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
]);

export default ServerOff;
