import createLucideIcon from '../createLucideIcon';

const CameraOff = createLucideIcon('CameraOffIcon', [
  ['line', { x1: '2', y1: '2', x2: '22', y2: '22', key: '1w4vcy' }],
  ['path', { d: 'M7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16', key: 'qmtpty' }],
  ['path', { d: 'M9.5 4h5L17 7h3a2 2 0 0 1 2 2v7.5', key: '1ufyfc' }],
  ['path', { d: 'M14.121 15.121A3 3 0 1 1 9.88 10.88', key: '11zox6' }],
]);

export default CameraOff;
