import createLucideIcon from '../createLucideIcon';

const FileKey = createLucideIcon('FileKeyIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['circle', { cx: '10', cy: '16', r: '2', key: '4ckbqe' }],
  ['path', { d: 'm16 10-4.5 4.5', key: '7p3ebg' }],
  ['path', { d: 'm15 11 1 1', key: '1bsyx3' }],
]);

export default FileKey;
