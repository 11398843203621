import createLucideIcon from '../createLucideIcon';

const SwitchCamera = createLucideIcon('SwitchCameraIcon', [
  ['path', { d: 'M11 19H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h5', key: 'mtk2lu' }],
  ['path', { d: 'M13 5h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-5', key: '120jsl' }],
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  ['path', { d: 'm18 22-3-3 3-3', key: 'kgdoj7' }],
  ['path', { d: 'm6 2 3 3-3 3', key: '1fnbkv' }],
]);

export default SwitchCamera;
