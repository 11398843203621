import createLucideIcon from '../createLucideIcon';

const Mic2 = createLucideIcon('Mic2Icon', [
  [
    'path',
    { d: 'm12 8-9.04 9.06a2.82 2.82 0 1 0 3.98 3.98L16 12', key: 'zoua8r' },
  ],
  ['circle', { cx: '17', cy: '7', r: '5', key: '1fomce' }],
]);

export default Mic2;
