import createLucideIcon from '../createLucideIcon';

const CalendarCheck = createLucideIcon('CalendarCheckIcon', [
  [
    'rect',
    {
      x: '3',
      y: '4',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: '1dtxiw',
    },
  ],
  ['line', { x1: '16', y1: '2', x2: '16', y2: '6', key: '18saeg' }],
  ['line', { x1: '8', y1: '2', x2: '8', y2: '6', key: '1u51jw' }],
  ['line', { x1: '3', y1: '10', x2: '21', y2: '10', key: '6sq0yj' }],
  ['path', { d: 'm9 16 2 2 4-4', key: '19s6y9' }],
]);

export default CalendarCheck;
