import createLucideIcon from '../createLucideIcon';

const BellMinus = createLucideIcon('BellMinusIcon', [
  ['path', { d: 'M13.73 21a2 2 0 0 1-3.46 0', key: '6o5tke' }],
  ['path', { d: 'M21 5h-6', key: '14nobq' }],
  [
    'path',
    {
      d: 'M18.021 9C18.29 15.193 21 17 21 17H3s3-2 3-9a6 6 0 0 1 7-5.916',
      key: '1wldvb',
    },
  ],
]);

export default BellMinus;
