import createLucideIcon from '../createLucideIcon';

const Flag = createLucideIcon('FlagIcon', [
  [
    'path',
    {
      d: 'M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z',
      key: 'i9b6wo',
    },
  ],
  ['line', { x1: '4', y1: '22', x2: '4', y2: '15', key: '1k23bw' }],
]);

export default Flag;
