import createLucideIcon from '../createLucideIcon';

const Refrigerator = createLucideIcon('RefrigeratorIcon', [
  [
    'path',
    {
      d: 'M5 6a4 4 0 0 1 4-4h6a4 4 0 0 1 4 4v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6Z',
      key: 'fpq118',
    },
  ],
  ['path', { d: 'M5 10h14', key: 'elsbfy' }],
  ['path', { d: 'M15 7v6', key: '1nx30x' }],
]);

export default Refrigerator;
