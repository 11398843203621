import createLucideIcon from '../createLucideIcon';

const FileJson = createLucideIcon('FileJsonIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  [
    'path',
    {
      d: 'M10 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1',
      key: '1oajmo',
    },
  ],
  [
    'path',
    {
      d: 'M14 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1',
      key: 'mpwhp6',
    },
  ],
]);

export default FileJson;
