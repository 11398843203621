import createLucideIcon from '../createLucideIcon';

const AlignStartVertical = createLucideIcon('AlignStartVerticalIcon', [
  [
    'rect',
    { x: '6', y: '14', width: '9', height: '6', rx: '2', key: 'pvftf3' },
  ],
  [
    'rect',
    { x: '6', y: '4', width: '16', height: '6', rx: '2', key: '1aj6m8' },
  ],
  ['path', { d: 'M2 2v20', key: '1ivd8o' }],
]);

export default AlignStartVertical;
