import createLucideIcon from '../createLucideIcon';

const Tent = createLucideIcon('TentIcon', [
  ['path', { d: 'M19 20 10 4', key: '1ak541' }],
  ['path', { d: 'm5 20 9-16', key: '11dtj9' }],
  ['path', { d: 'M3 20h18', key: '1l19wn' }],
  ['path', { d: 'm12 15-3 5', key: '1c5kej' }],
  ['path', { d: 'm12 15 3 5', key: 'odkmhi' }],
]);

export default Tent;
