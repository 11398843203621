import createLucideIcon from '../createLucideIcon';

const TerminalSquare = createLucideIcon('TerminalSquareIcon', [
  ['path', { d: 'm7 11 2-2-2-2', key: '1lz0vl' }],
  ['path', { d: 'M11 13h4', key: '1p7l4v' }],
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
]);

export default TerminalSquare;
