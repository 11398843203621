import createLucideIcon from '../createLucideIcon';

const Plug = createLucideIcon('PlugIcon', [
  ['path', { d: 'M12 22v-5', key: '1ega77' }],
  ['path', { d: 'M9 7V2', key: '1r97uf' }],
  ['path', { d: 'M15 7V2', key: '1uo4jc' }],
  [
    'path',
    { d: 'M6 13V8h12v5a4 4 0 0 1-4 4h-4a4 4 0 0 1-4-4Z', key: '1xki7n' },
  ],
]);

export default Plug;
