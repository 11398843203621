import createLucideIcon from '../createLucideIcon';

const ClipboardList = createLucideIcon('ClipboardListIcon', [
  [
    'rect',
    {
      x: '8',
      y: '2',
      width: '8',
      height: '4',
      rx: '1',
      ry: '1',
      key: 'wz2j3u',
    },
  ],
  [
    'path',
    {
      d: 'M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2',
      key: '116196',
    },
  ],
  ['path', { d: 'M12 11h4', key: '1jrz19' }],
  ['path', { d: 'M12 16h4', key: 'n85exb' }],
  ['path', { d: 'M8 11h.01', key: '1dfujw' }],
  ['path', { d: 'M8 16h.01', key: '18s6g9' }],
]);

export default ClipboardList;
