import createLucideIcon from '../createLucideIcon';

const Haze = createLucideIcon('HazeIcon', [
  ['path', { d: 'm5.2 6.2 1.4 1.4', key: '17imol' }],
  ['path', { d: 'M2 13h2', key: '13gyu8' }],
  ['path', { d: 'M20 13h2', key: '16rner' }],
  ['path', { d: 'm17.4 7.6 1.4-1.4', key: 't4xlah' }],
  ['path', { d: 'M22 17H2', key: '1gtaj3' }],
  ['path', { d: 'M22 21H2', key: '1gy6en' }],
  ['path', { d: 'M16 13a4 4 0 0 0-8 0', key: '1dyczq' }],
  ['path', { d: 'M12 5V2.5', key: '1vytko' }],
]);

export default Haze;
