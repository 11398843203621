import createLucideIcon from '../createLucideIcon';

const FileSymlink = createLucideIcon('FileSymlinkIcon', [
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v7', key: '138uzh' },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'm10 18 3-3-3-3', key: '18f6ys' }],
  ['path', { d: 'M4 18v-1a2 2 0 0 1 2-2h6', key: '5uz2rn' }],
]);

export default FileSymlink;
