import createLucideIcon from '../createLucideIcon';

const SmartphoneCharging = createLucideIcon('SmartphoneChargingIcon', [
  [
    'rect',
    {
      x: '5',
      y: '2',
      width: '14',
      height: '20',
      rx: '2',
      ry: '2',
      key: '1gcc4z',
    },
  ],
  ['path', { d: 'M12.667 8 10 12h4l-2.667 4', key: 'h9lk2d' }],
]);

export default SmartphoneCharging;
