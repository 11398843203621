import createLucideIcon from '../createLucideIcon';

const PersonStanding = createLucideIcon('PersonStandingIcon', [
  ['circle', { cx: '12', cy: '5', r: '1', key: 'gxeob9' }],
  ['path', { d: 'm9 20 3-6 3 6', key: 'se2kox' }],
  ['path', { d: 'm6 8 6 2 6-2', key: '4o3us4' }],
  ['path', { d: 'M12 10v4', key: '1kjpxc' }],
]);

export default PersonStanding;
