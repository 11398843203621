import createLucideIcon from '../createLucideIcon';

const Figma = createLucideIcon('FigmaIcon', [
  [
    'path',
    {
      d: 'M5 5.5A3.5 3.5 0 0 1 8.5 2H12v7H8.5A3.5 3.5 0 0 1 5 5.5z',
      key: '1340ok',
    },
  ],
  ['path', { d: 'M12 2h3.5a3.5 3.5 0 1 1 0 7H12V2z', key: '1hz3m3' }],
  [
    'path',
    { d: 'M12 12.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 1 1-7 0z', key: '1oz8n2' },
  ],
  [
    'path',
    {
      d: 'M5 19.5A3.5 3.5 0 0 1 8.5 16H12v3.5a3.5 3.5 0 1 1-7 0z',
      key: '1ff65i',
    },
  ],
  [
    'path',
    {
      d: 'M5 12.5A3.5 3.5 0 0 1 8.5 9H12v7H8.5A3.5 3.5 0 0 1 5 12.5z',
      key: 'pdip6e',
    },
  ],
]);

export default Figma;
