import createLucideIcon from '../createLucideIcon';

const UserCog = createLucideIcon('UserCogIcon', [
  ['path', { d: 'M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2', key: '1yyitq' }],
  ['circle', { cx: '9', cy: '7', r: '4', key: 'nufk8' }],
  ['circle', { cx: '19', cy: '11', r: '2', key: '1rxg02' }],
  ['path', { d: 'M19 8v1', key: '1iffrw' }],
  ['path', { d: 'M19 13v1', key: 'z4xc62' }],
  ['path', { d: 'm21.6 9.5-.87.5', key: '6lxupl' }],
  ['path', { d: 'm17.27 12-.87.5', key: '1rwhxx' }],
  ['path', { d: 'm21.6 12.5-.87-.5', key: 'agvc9a' }],
  ['path', { d: 'm17.27 10-.87-.5', key: '12d57s' }],
]);

export default UserCog;
