import createLucideIcon from '../createLucideIcon';

const Heading4 = createLucideIcon('Heading4Icon', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  ['path', { d: 'M17 10v4h4', key: '13sv97' }],
  ['path', { d: 'M21 10v8', key: '1kdml4' }],
]);

export default Heading4;
