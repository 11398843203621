import createLucideIcon from '../createLucideIcon';

const RadioReceiver = createLucideIcon('RadioReceiverIcon', [
  ['path', { d: 'M5 16v2', key: 'g5qcv5' }],
  ['path', { d: 'M19 16v2', key: '1gbaio' }],
  [
    'rect',
    { x: '2', y: '8', width: '20', height: '8', rx: '2', key: '1xflse' },
  ],
  ['path', { d: 'M18 12h0', key: '1ucjzd' }],
]);

export default RadioReceiver;
