import createLucideIcon from '../createLucideIcon';

const AlignCenterVertical = createLucideIcon('AlignCenterVerticalIcon', [
  ['path', { d: 'M12 2v20', key: 't6zp3m' }],
  ['path', { d: 'M8 10H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2h4', key: '14d6g8' }],
  ['path', { d: 'M16 10h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4', key: '1e2lrw' }],
  ['path', { d: 'M8 20H7a2 2 0 0 1-2-2v-2c0-1.1.9-2 2-2h1', key: '1fkdwx' }],
  ['path', { d: 'M16 14h1a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2h-1', key: '1euafb' }],
]);

export default AlignCenterVertical;
