import createLucideIcon from '../createLucideIcon';

const FileBarChart = createLucideIcon('FileBarChartIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M12 18v-4', key: 'q1q25u' }],
  ['path', { d: 'M8 18v-2', key: 'qcmpov' }],
  ['path', { d: 'M16 18v-6', key: '15y0np' }],
]);

export default FileBarChart;
