import createLucideIcon from '../createLucideIcon';

const Bomb = createLucideIcon('BombIcon', [
  ['circle', { cx: '11', cy: '13', r: '9', key: 'hd149' }],
  [
    'path',
    {
      d: 'm19.5 9.5 1.8-1.8a2.4 2.4 0 0 0 0-3.4l-1.6-1.6a2.41 2.41 0 0 0-3.4 0l-1.8 1.8',
      key: '9owvxi',
    },
  ],
  ['path', { d: 'm22 2-1.5 1.5', key: 'ay92ug' }],
]);

export default Bomb;
