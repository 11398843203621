import createLucideIcon from '../createLucideIcon';

const FolderInput = createLucideIcon('FolderInputIcon', [
  [
    'path',
    {
      d: 'M2 9V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-1',
      key: '1gu97c',
    },
  ],
  ['path', { d: 'M2 13h10', key: 'pgb2dq' }],
  ['path', { d: 'm9 16 3-3-3-3', key: '6m91ic' }],
]);

export default FolderInput;
