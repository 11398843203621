import createLucideIcon from '../createLucideIcon';

const ArrowUpDown = createLucideIcon('ArrowUpDownIcon', [
  ['polyline', { points: '11 17 7 21 3 17', key: 'dv0ycv' }],
  ['line', { x1: '7', y1: '21', x2: '7', y2: '9', key: '1cxv4h' }],
  ['polyline', { points: '21 7 17 3 13 7', key: '1su31j' }],
  ['line', { x1: '17', y1: '15', x2: '17', y2: '3', key: 'r3527w' }],
]);

export default ArrowUpDown;
