import createLucideIcon from '../createLucideIcon';

const Columns = createLucideIcon('ColumnsIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['line', { x1: '12', y1: '3', x2: '12', y2: '21', key: 'essbwb' }],
]);

export default Columns;
