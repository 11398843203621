import createLucideIcon from '../createLucideIcon';

const FolderCog = createLucideIcon('FolderCogIcon', [
  [
    'path',
    {
      d: 'M10.5 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2v3',
      key: '1g1zaq',
    },
  ],
  ['circle', { cx: '18', cy: '18', r: '3', key: '1xkwt0' }],
  ['path', { d: 'M18 14v1', key: '1sx9hk' }],
  ['path', { d: 'M18 21v1', key: 'pviwm2' }],
  ['path', { d: 'M22 18h-1', key: 'phgwqy' }],
  ['path', { d: 'M15 18h-1', key: '1v9fvv' }],
  ['path', { d: 'm21 15-.88.88', key: '13nfy4' }],
  ['path', { d: 'M15.88 20.12 15 21', key: 'qplfkl' }],
  ['path', { d: 'm21 21-.88-.88', key: '1ryrr1' }],
  ['path', { d: 'M15.88 15.88 15 15', key: '4terp3' }],
]);

export default FolderCog;
