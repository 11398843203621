import createLucideIcon from '../createLucideIcon';

const Pocket = createLucideIcon('PocketIcon', [
  [
    'path',
    {
      d: 'M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z',
      key: '1mz881',
    },
  ],
  ['polyline', { points: '8 10 12 14 16 10', key: 'w4mbv5' }],
]);

export default Pocket;
