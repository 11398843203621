import createLucideIcon from '../createLucideIcon';

const CalendarCheck2 = createLucideIcon('CalendarCheck2Icon', [
  [
    'path',
    {
      d: 'M21 14V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8',
      key: 'bce9hv',
    },
  ],
  ['line', { x1: '16', y1: '2', x2: '16', y2: '6', key: '18saeg' }],
  ['line', { x1: '8', y1: '2', x2: '8', y2: '6', key: '1u51jw' }],
  ['line', { x1: '3', y1: '10', x2: '21', y2: '10', key: '6sq0yj' }],
  ['path', { d: 'm16 20 2 2 4-4', key: '13tcca' }],
]);

export default CalendarCheck2;
