import createLucideIcon from '../createLucideIcon';

const ThermometerSun = createLucideIcon('ThermometerSunIcon', [
  ['path', { d: 'M12 9a4 4 0 0 0-2 7.5', key: '1jvsq6' }],
  ['path', { d: 'M12 3v2', key: '1w22ol' }],
  ['path', { d: 'm6.6 18.4-1.4 1.4', key: 'w2yidj' }],
  ['path', { d: 'M20 4v10.54a4 4 0 1 1-4 0V4a2 2 0 0 1 4 0Z', key: 'iof6y5' }],
  ['path', { d: 'M4 13H2', key: '118le4' }],
  ['path', { d: 'M6.34 7.34 4.93 5.93', key: '1brd51' }],
]);

export default ThermometerSun;
