import createLucideIcon from '../createLucideIcon';

const Cloudy = createLucideIcon('CloudyIcon', [
  [
    'path',
    { d: 'M17.5 21H9a7 7 0 1 1 6.71-9h1.79a4.5 4.5 0 1 1 0 9Z', key: 'gqqjvc' },
  ],
  [
    'path',
    {
      d: 'M22 10a3 3 0 0 0-3-3h-2.207a5.502 5.502 0 0 0-10.702.5',
      key: '1p2s76',
    },
  ],
]);

export default Cloudy;
