import createLucideIcon from '../createLucideIcon';

const Cpu = createLucideIcon('CpuIcon', [
  [
    'rect',
    {
      x: '4',
      y: '4',
      width: '16',
      height: '16',
      rx: '2',
      ry: '2',
      key: '19czt8',
    },
  ],
  ['rect', { x: '9', y: '9', width: '6', height: '6', key: 'o3kz5p' }],
  ['line', { x1: '9', y1: '2', x2: '9', y2: '4', key: '1tcqwn' }],
  ['line', { x1: '15', y1: '2', x2: '15', y2: '4', key: '1yvlak' }],
  ['line', { x1: '9', y1: '21', x2: '9', y2: '22', key: 'tuhu08' }],
  ['line', { x1: '15', y1: '20', x2: '15', y2: '22', key: '1o5cyu' }],
  ['line', { x1: '20', y1: '9', x2: '22', y2: '9', key: 'b8rlm1' }],
  ['line', { x1: '20', y1: '14', x2: '22', y2: '14', key: '1mk8c1' }],
  ['line', { x1: '2', y1: '9', x2: '4', y2: '9', key: 'bt7uvh' }],
  ['line', { x1: '2', y1: '14', x2: '4', y2: '14', key: 'hp71sd' }],
]);

export default Cpu;
