import createLucideIcon from '../createLucideIcon';

const FileAxis3d = createLucideIcon('FileAxis3dIcon', [
  [
    'path',
    {
      d: 'M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z',
      key: '1nnpy2',
    },
  ],
  ['polyline', { points: '14 2 14 8 20 8', key: '1ew0cm' }],
  ['path', { d: 'M8 10v8h8', key: 'tlaukw' }],
  ['path', { d: 'm8 18 4-4', key: '12zab0' }],
]);

export default FileAxis3d;
