import createLucideIcon from '../createLucideIcon';

const TimerOff = createLucideIcon('TimerOffIcon', [
  ['path', { d: 'M10 2h4', key: 'n1abiw' }],
  ['path', { d: 'M4.6 11a8 8 0 0 0 1.7 8.7 8 8 0 0 0 8.7 1.7', key: '10he05' }],
  ['path', { d: 'M7.4 7.4a8 8 0 0 1 10.3 1 8 8 0 0 1 .9 10.2', key: '15f7sh' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M12 12v-2', key: 'fwoke6' }],
]);

export default TimerOff;
