import createLucideIcon from '../createLucideIcon';

const Meh = createLucideIcon('MehIcon', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['line', { x1: '8', y1: '15', x2: '16', y2: '15', key: '29ieok' }],
  ['line', { x1: '9', y1: '9', x2: '9.01', y2: '9', key: '141aaf' }],
  ['line', { x1: '15', y1: '9', x2: '15.01', y2: '9', key: '1cyg3o' }],
]);

export default Meh;
