import createLucideIcon from '../createLucideIcon';

const Crosshair = createLucideIcon('CrosshairIcon', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['line', { x1: '22', y1: '12', x2: '18', y2: '12', key: 'yivkn6' }],
  ['line', { x1: '6', y1: '12', x2: '2', y2: '12', key: 'hlzxjj' }],
  ['line', { x1: '12', y1: '6', x2: '12', y2: '2', key: '1s1957' }],
  ['line', { x1: '12', y1: '22', x2: '12', y2: '18', key: '2x08zm' }],
]);

export default Crosshair;
