import createLucideIcon from '../createLucideIcon';

const Usb = createLucideIcon('UsbIcon', [
  ['circle', { cx: '4', cy: '20', r: '1', key: '22iqad' }],
  ['circle', { cx: '10', cy: '7', r: '1', key: 'dypaad' }],
  ['path', { d: 'M4 20 19 5', key: '15hogs' }],
  ['path', { d: 'm21 3-3 1 2 2 1-3Z', key: 'ew8vct' }],
  ['path', { d: 'm10 7-5 5 2 5', key: '148pqf' }],
  ['path', { d: 'm10 14 5 2 4-4', key: '1ivjwr' }],
  ['path', { d: 'm18 12 1-1 1 1-1 1-1-1Z', key: 'tus6kn' }],
]);

export default Usb;
