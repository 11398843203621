import createLucideIcon from '../createLucideIcon';

const Sofa = createLucideIcon('SofaIcon', [
  ['path', { d: 'M20 9V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v3', key: '1dgpiv' }],
  [
    'path',
    {
      d: 'M2 11v5a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-5a2 2 0 0 0-4 0v2H6v-2a2 2 0 0 0-4 0Z',
      key: 'u5qfb7',
    },
  ],
  ['path', { d: 'M4 18v2', key: 'jwo5n2' }],
  ['path', { d: 'M20 18v2', key: '1ar1qi' }],
  ['path', { d: 'M12 4v9', key: 'oqhhn3' }],
]);

export default Sofa;
