import createLucideIcon from '../createLucideIcon';

const SidebarClose = createLucideIcon('SidebarCloseIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['path', { d: 'M9 3v18', key: 'fh3hqa' }],
  ['path', { d: 'm16 15-3-3 3-3', key: '14y99z' }],
]);

export default SidebarClose;
