import createLucideIcon from '../createLucideIcon';

const Airplay = createLucideIcon('AirplayIcon', [
  [
    'path',
    {
      d: 'M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1',
      key: 'ns4c3b',
    },
  ],
  ['polygon', { points: '12 15 17 21 7 21 12 15', key: '1sy95i' }],
]);

export default Airplay;
