import createLucideIcon from '../createLucideIcon';

const Sandwich = createLucideIcon('SandwichIcon', [
  ['path', { d: 'M3 11v3a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-3', key: '34v9d7' }],
  [
    'path',
    {
      d: 'M12 19H4a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-3.83',
      key: '1k5vfb',
    },
  ],
  ['path', { d: 'm3 11 7.77-6.04a2 2 0 0 1 2.46 0L21 11H3Z', key: '1oe7l6' }],
  [
    'path',
    { d: 'M12.97 19.77 7 15h12.5l-3.75 4.5a2 2 0 0 1-2.78.27Z', key: '1ts2ri' },
  ],
]);

export default Sandwich;
