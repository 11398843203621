import createLucideIcon from '../createLucideIcon';

const FolderClock = createLucideIcon('FolderClockIcon', [
  [
    'path',
    {
      d: 'M7 20H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3.93a2 2 0 0 1 1.66.9l.82 1.2a2 2 0 0 0 1.66.9H20a2 2 0 0 1 2 2',
      key: '1p0xo9',
    },
  ],
  ['circle', { cx: '16', cy: '16', r: '6', key: 'qoo3c4' }],
  ['path', { d: 'M16 14v2l1 1', key: 'xth2jh' }],
]);

export default FolderClock;
