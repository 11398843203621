import createLucideIcon from '../createLucideIcon';

const AlignVerticalDistributeEnd = createLucideIcon(
  'AlignVerticalDistributeEndIcon',
  [
    [
      'rect',
      { x: '5', y: '14', width: '14', height: '6', rx: '2', key: '1qrzuf' },
    ],
    [
      'rect',
      { x: '7', y: '4', width: '10', height: '6', rx: '2', key: 'we8e9z' },
    ],
    ['path', { d: 'M2 20h20', key: 'owomy5' }],
    ['path', { d: 'M2 10h20', key: '1ir3d8' }],
  ],
);

export default AlignVerticalDistributeEnd;
