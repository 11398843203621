import createLucideIcon from '../createLucideIcon';

const AlignHorizontalJustifyCenter = createLucideIcon(
  'AlignHorizontalJustifyCenterIcon',
  [
    [
      'rect',
      { x: '2', y: '5', width: '6', height: '14', rx: '2', key: '15angl' },
    ],
    [
      'rect',
      { x: '16', y: '7', width: '6', height: '10', rx: '2', key: '1di99g' },
    ],
    ['path', { d: 'M12 2v20', key: 't6zp3m' }],
  ],
);

export default AlignHorizontalJustifyCenter;
