import createLucideIcon from '../createLucideIcon';

const Filter = createLucideIcon('FilterIcon', [
  [
    'polygon',
    { points: '22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3', key: '1yg77f' },
  ],
]);

export default Filter;
