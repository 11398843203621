import createLucideIcon from '../createLucideIcon';

const Spline = createLucideIcon('SplineIcon', [
  [
    'path',
    {
      d: 'M21 6V4c0-.6-.4-1-1-1h-2a1 1 0 0 0-1 1v2c0 .6.4 1 1 1h2c.6 0 1-.4 1-1Z',
      key: '1alwvo',
    },
  ],
  [
    'path',
    {
      d: 'M7 20v-2c0-.6-.4-1-1-1H4a1 1 0 0 0-1 1v2c0 .6.4 1 1 1h2c.6 0 1-.4 1-1Z',
      key: 'f14zj6',
    },
  ],
  ['path', { d: 'M5 17A12 12 0 0 1 17 5', key: '1okkup' }],
]);

export default Spline;
