import createLucideIcon from '../createLucideIcon';

const Mails = createLucideIcon('MailsIcon', [
  [
    'rect',
    { x: '6', y: '4', width: '16', height: '13', rx: '2', key: 'q6n4z8' },
  ],
  ['path', { d: 'm22 7-7.1 3.78c-.57.3-1.23.3-1.8 0L6 7', key: 'xn252p' }],
  ['path', { d: 'M2 8v11c0 1.1.9 2 2 2h14', key: 'n13cji' }],
]);

export default Mails;
