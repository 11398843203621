import createLucideIcon from '../createLucideIcon';

const Cake = createLucideIcon('CakeIcon', [
  ['path', { d: 'M20 21v-8a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v8', key: '1w3rig' }],
  [
    'path',
    {
      d: 'M4 16s.5-1 2-1 2.5 2 4 2 2.5-2 4-2 2.5 2 4 2 2-1 2-1',
      key: 'n2jgmb',
    },
  ],
  ['path', { d: 'M2 21h20', key: '1nyx9w' }],
  ['path', { d: 'M7 8v2', key: 'kqglng' }],
  ['path', { d: 'M12 8v2', key: '1woqiv' }],
  ['path', { d: 'M17 8v2', key: 'teptal' }],
  ['path', { d: 'M7 4h.01', key: '1bh4kh' }],
  ['path', { d: 'M12 4h.01', key: '1ujb9j' }],
  ['path', { d: 'M17 4h.01', key: '1upcoc' }],
]);

export default Cake;
