import createLucideIcon from '../createLucideIcon';

const Outdent = createLucideIcon('OutdentIcon', [
  ['polyline', { points: '7 8 3 12 7 16', key: '2j60jr' }],
  ['line', { x1: '21', y1: '12', x2: '11', y2: '12', key: '1xy73i' }],
  ['line', { x1: '21', y1: '6', x2: '11', y2: '6', key: '97xvqg' }],
  ['line', { x1: '21', y1: '18', x2: '11', y2: '18', key: '1r7j8g' }],
]);

export default Outdent;
