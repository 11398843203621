import createLucideIcon from '../createLucideIcon';

const RectangleVertical = createLucideIcon('RectangleVerticalIcon', [
  [
    'rect',
    { x: '6', y: '2', width: '12', height: '20', rx: '2', key: '749fme' },
  ],
]);

export default RectangleVertical;
