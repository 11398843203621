import createLucideIcon from '../createLucideIcon';

const AlignHorizontalDistributeStart = createLucideIcon(
  'AlignHorizontalDistributeStartIcon',
  [
    [
      'rect',
      { x: '4', y: '5', width: '6', height: '14', rx: '2', key: 'ric6yp' },
    ],
    [
      'rect',
      { x: '14', y: '7', width: '6', height: '10', rx: '2', key: '1mr5t1' },
    ],
    ['path', { d: 'M4 2v20', key: 'gtpd5x' }],
    ['path', { d: 'M14 2v20', key: 'tg6bpw' }],
  ],
);

export default AlignHorizontalDistributeStart;
