import createLucideIcon from '../createLucideIcon';

const FunctionSquare = createLucideIcon('FunctionSquareIcon', [
  [
    'rect',
    {
      x: '3',
      y: '3',
      width: '18',
      height: '18',
      rx: '2',
      ry: '2',
      key: 'maln0c',
    },
  ],
  ['path', { d: 'M9 17c2 0 2.8-1 2.8-2.8V10c0-2 1-3.3 3.2-3', key: 'm1af9g' }],
  ['path', { d: 'M9 11.2h5.7', key: '3zgcl2' }],
]);

export default FunctionSquare;
