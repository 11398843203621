import createLucideIcon from '../createLucideIcon';

const Factory = createLucideIcon('FactoryIcon', [
  [
    'path',
    {
      d: 'M2 20a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8l-7 5V8l-7 5V4a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2Z',
      key: '159hny',
    },
  ],
  ['path', { d: 'M17 18h1', key: 'uldtlt' }],
  ['path', { d: 'M12 18h1', key: 's9uhes' }],
  ['path', { d: 'M7 18h1', key: '1neino' }],
]);

export default Factory;
