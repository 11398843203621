import createLucideIcon from '../createLucideIcon';

const Network = createLucideIcon('NetworkIcon', [
  ['rect', { x: '9', y: '2', width: '6', height: '6', key: '1iwon9' }],
  ['rect', { x: '16', y: '16', width: '6', height: '6', key: 'gonbwd' }],
  ['rect', { x: '2', y: '16', width: '6', height: '6', key: '1q0lzr' }],
  ['path', { d: 'M5 16v-4h14v4', key: '8njgxx' }],
  ['path', { d: 'M12 12V8', key: '2874zd' }],
]);

export default Network;
