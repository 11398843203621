import createLucideIcon from '../createLucideIcon';

const CloudSnow = createLucideIcon('CloudSnowIcon', [
  [
    'path',
    {
      d: 'M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242',
      key: '1pljnt',
    },
  ],
  ['path', { d: 'M8 15h.01', key: 'a7atzg' }],
  ['path', { d: 'M8 19h.01', key: 'puxtts' }],
  ['path', { d: 'M12 17h.01', key: 'p32p05' }],
  ['path', { d: 'M12 21h.01', key: 'h35vbk' }],
  ['path', { d: 'M16 15h.01', key: 'rnfrdf' }],
  ['path', { d: 'M16 19h.01', key: '1vcnzz' }],
]);

export default CloudSnow;
