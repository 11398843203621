import createLucideIcon from '../createLucideIcon';

const Boxes = createLucideIcon('BoxesIcon', [
  [
    'path',
    {
      d: 'M2.97 12.92A2 2 0 0 0 2 14.63v3.24a2 2 0 0 0 .97 1.71l3 1.8a2 2 0 0 0 2.06 0L12 19v-5.5l-5-3-4.03 2.42Z',
      key: 'lc1i9w',
    },
  ],
  ['path', { d: 'm7 16.5-4.74-2.85', key: '1o9zyk' }],
  ['path', { d: 'm7 16.5 5-3', key: 'va8pkn' }],
  ['path', { d: 'M7 16.5v5.17', key: 'jnp8gn' }],
  [
    'path',
    {
      d: 'M12 13.5V19l3.97 2.38a2 2 0 0 0 2.06 0l3-1.8a2 2 0 0 0 .97-1.71v-3.24a2 2 0 0 0-.97-1.71L17 10.5l-5 3Z',
      key: '8zsnat',
    },
  ],
  ['path', { d: 'm17 16.5-5-3', key: '8arw3v' }],
  ['path', { d: 'm17 16.5 4.74-2.85', key: '8rfmw' }],
  ['path', { d: 'M17 16.5v5.17', key: 'k6z78m' }],
  [
    'path',
    {
      d: 'M7.97 4.42A2 2 0 0 0 7 6.13v4.37l5 3 5-3V6.13a2 2 0 0 0-.97-1.71l-3-1.8a2 2 0 0 0-2.06 0l-3 1.8Z',
      key: '1xygjf',
    },
  ],
  ['path', { d: 'M12 8 7.26 5.15', key: '1vbdud' }],
  ['path', { d: 'm12 8 4.74-2.85', key: '3rx089' }],
  ['path', { d: 'M12 13.5V8', key: '1io7kd' }],
]);

export default Boxes;
