import createLucideIcon from '../createLucideIcon';

const Heading5 = createLucideIcon('Heading5Icon', [
  ['path', { d: 'M4 12h8', key: '17cfdx' }],
  ['path', { d: 'M4 18V6', key: '1rz3zl' }],
  ['path', { d: 'M12 18V6', key: 'zqpxq5' }],
  ['path', { d: 'M17 13v-3h4', key: '1nvgqp' }],
  [
    'path',
    {
      d: 'M17 17.7c.4.2.8.3 1.3.3 1.5 0 2.7-1.1 2.7-2.5S19.8 13 18.3 13H17',
      key: '2nebdn',
    },
  ],
]);

export default Heading5;
