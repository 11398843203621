import createLucideIcon from '../createLucideIcon';

const RectangleHorizontal = createLucideIcon('RectangleHorizontalIcon', [
  [
    'rect',
    { x: '2', y: '6', width: '20', height: '12', rx: '2', key: '1wpnh2' },
  ],
]);

export default RectangleHorizontal;
