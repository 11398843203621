import createLucideIcon from '../createLucideIcon';

const XOctagon = createLucideIcon('XOctagonIcon', [
  [
    'polygon',
    {
      points:
        '7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2',
      key: 'h1p8hx',
    },
  ],
  ['line', { x1: '15', y1: '9', x2: '9', y2: '15', key: '19zs77' }],
  ['line', { x1: '9', y1: '9', x2: '15', y2: '15', key: '10u9bu' }],
]);

export default XOctagon;
