import createLucideIcon from '../createLucideIcon';

const Currency = createLucideIcon('CurrencyIcon', [
  ['circle', { cx: '12', cy: '12', r: '8', key: '46899m' }],
  ['line', { x1: '3', y1: '3', x2: '6', y2: '6', key: 'zkqcdn' }],
  ['line', { x1: '21', y1: '3', x2: '18', y2: '6', key: 'n7spic' }],
  ['line', { x1: '3', y1: '21', x2: '6', y2: '18', key: '7xq9ok' }],
  ['line', { x1: '21', y1: '21', x2: '18', y2: '18', key: 'mzvtez' }],
]);

export default Currency;
