import createLucideIcon from '../createLucideIcon';

const Compass = createLucideIcon('CompassIcon', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  [
    'polygon',
    {
      points: '16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76',
      key: 'm9r19z',
    },
  ],
]);

export default Compass;
