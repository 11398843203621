import createLucideIcon from '../createLucideIcon';

const Grab = createLucideIcon('GrabIcon', [
  ['path', { d: 'M18 11.5V9a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v1.4', key: 'n5nng' }],
  ['path', { d: 'M14 10V8a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v2', key: '185i9d' }],
  ['path', { d: 'M10 9.9V9a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v5', key: '11pz95' }],
  ['path', { d: 'M6 14v0a2 2 0 0 0-2-2v0a2 2 0 0 0-2 2v0', key: '16yk7l' }],
  [
    'path',
    {
      d: 'M18 11v0a2 2 0 1 1 4 0v3a8 8 0 0 1-8 8h-4a8 8 0 0 1-8-8 2 2 0 1 1 4 0',
      key: 'nzvb1c',
    },
  ],
]);

export default Grab;
