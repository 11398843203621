import createLucideIcon from '../createLucideIcon';

const CloudCog = createLucideIcon('CloudCogIcon', [
  [
    'path',
    { d: 'M20 16.2A4.5 4.5 0 0 0 17.5 8h-1.8A7 7 0 1 0 4 14.9', key: '19hoja' },
  ],
  ['circle', { cx: '12', cy: '17', r: '3', key: '1spfwm' }],
  ['path', { d: 'M12 13v1', key: '176q98' }],
  ['path', { d: 'M12 20v1', key: '1wcdkc' }],
  ['path', { d: 'M16 17h-1', key: 'y560le' }],
  ['path', { d: 'M9 17H8', key: '1lfe9z' }],
  ['path', { d: 'm15 14-.88.88', key: '12ytk1' }],
  ['path', { d: 'M9.88 19.12 9 20', key: '1kmb4r' }],
  ['path', { d: 'm15 20-.88-.88', key: '1ipjcf' }],
  ['path', { d: 'M9.88 14.88 9 14', key: 'c4uok7' }],
]);

export default CloudCog;
