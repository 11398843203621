import createLucideIcon from '../createLucideIcon';

const Link = createLucideIcon('LinkIcon', [
  [
    'path',
    {
      d: 'M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71',
      key: '1cjeqo',
    },
  ],
  [
    'path',
    {
      d: 'M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71',
      key: '19qd67',
    },
  ],
]);

export default Link;
