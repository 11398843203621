import createLucideIcon from '../createLucideIcon';

const Cast = createLucideIcon('CastIcon', [
  [
    'path',
    {
      d: 'M2 8V6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2h-6',
      key: '3zrzxg',
    },
  ],
  ['path', { d: 'M2 12a9 9 0 0 1 8 8', key: 'g6cvee' }],
  ['path', { d: 'M2 16a5 5 0 0 1 4 4', key: '1y1dii' }],
  ['line', { x1: '2', y1: '20', x2: '2.01', y2: '20', key: '1cypae' }],
]);

export default Cast;
