import createLucideIcon from '../createLucideIcon';

const ListMusic = createLucideIcon('ListMusicIcon', [
  ['path', { d: 'M21 15V6', key: 'h1cx4g' }],
  [
    'path',
    { d: 'M18.5 18a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z', key: '8saifv' },
  ],
  ['path', { d: 'M12 12H3', key: '18klou' }],
  ['path', { d: 'M16 6H3', key: '1wxfjs' }],
  ['path', { d: 'M12 18H3', key: '11ftsu' }],
]);

export default ListMusic;
